import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../images/logo-svg.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faSearch } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import data from "../Pages/servicedata";
//import SearchComponent from '../Pages/SearchComponent'

function Header() {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const [searchError, setSearchError] = useState(false);

  const [showNavbar, setShowNavbar] = React.useState(false);
  //const [isHovered, setIsHovered] = useState(false);

  const handlePopupToggle = () => {
    setPopupOpen(!popupOpen);
    setSearchError(false);
    setSearchQuery(""); // Reset the search input value
    setSearchResults([]);
  };

  // const handleSearch = () => {
  //   if (searchQuery.trim().length < 5) {
  //     setSearchError(true); // Set search error flag to true
  //     setSearchResults([]);
  //     return;
  //   }

  //   const results = [];
  //   // Iterate over each key in the data object
  //   Object.keys(data).forEach((key) => {
  //     const category = data[key];
  //     // Check if title or URL includes the search query
  //     if (
  //       category.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //       category.url.toLowerCase().includes(searchQuery.toLowerCase())
  //     ) {
  //       // If it matches, add it to results
  //       results.push({
  //         title: category.title,
  //         url: category.url,
  //         shorttitle: category.shorttitle,
  //       });
  //     }
  //   });

  //   setSearchError(results.length === 0); // Set search error flag based on results
  //   setSearchResults(results);
  // };

  // const handleSearch = () => {
  //   if (searchQuery.trim().length < 2) {
  //     setSearchError(true); // Set search error flag to true
  //     setSearchResults([]);
  //     return;
  //   }
  
  //   const results = [];
  
  //   Object.keys(data).forEach((key) => {
  //     const category = data[key];
  //     const { title, url, shorttitle, description } = category;
  
  //     // Prepare descriptions for searching
  //     let descriptions = '';
  
  //     // Handle different types of description structures
  //     if (Array.isArray(description)) {
  //       description.forEach((desc) => {
  //         if (typeof desc === 'string') {
  //           descriptions += desc.toLowerCase() + ' ';
  //         } else if (typeof desc === 'object') {
  //           Object.values(desc).forEach((value) => {
  //             if (typeof value === 'string') {
  //               descriptions += value.toLowerCase() + ' ';
  //             } else if (Array.isArray(value)) {
  //               value.forEach((item) => {
  //                 if (typeof item === 'string') {
  //                   descriptions += item.toLowerCase() + ' ';
  //                 }
  //               });
  //             }
  //           });
  //         }
  //       });
  //     } else if (typeof description === 'object') {
  //       Object.values(description).forEach((value) => {
  //         if (typeof value === 'string') {
  //           descriptions += value.toLowerCase() + ' ';
  //         } else if (Array.isArray(value)) {
  //           value.forEach((item) => {
  //             if (typeof item === 'string') {
  //               descriptions += item.toLowerCase() + ' ';
  //             }
  //           });
  //         }
  //       });
  //     } else if (typeof description === 'string') {
  //       descriptions = description.toLowerCase();
  //     }
  
  //     // Check if title, url, or descriptions include the search query
  //     if (
  //       title.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //       url.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //       descriptions.includes(searchQuery.toLowerCase())
  //     ) {
  //       results.push({
  //         title,
  //         url,
  //         shorttitle,
  //         description, // Include description if needed
  //       });
  //     }
  //   });
  
  //   setSearchError(results.length === 0); // Set search error flag based on results
  //   setSearchResults(results);
  // };
  
  ///thsi one ---
  const handleSearch = () => {
    if (!searchQuery || typeof searchQuery !== 'string' || searchQuery.trim().length < 5) {
      setSearchError(true); // Set search error flag to true
      setSearchResults([]);
      return;
    }
  
    const results = [];
    
    Object.keys(data).forEach((key) => {
      const category = data[key];
      console.log('Category:', category); // Log category to debug
      
      // Convert to lowercase for case-insensitive comparison
      const searchLowerCase = searchQuery.toLowerCase();
      
      // Check if category and its properties are defined
      if (category && category.title && category.url) {
        let descriptions = '';
        
        // Example handling for description array
        if (Array.isArray(category.description)) {
          category.description.forEach(desc => {
            if (typeof desc === 'string') {
              descriptions += desc.toLowerCase() + ' ';
            }
          });
        } else if (typeof category.description === 'object') {
          // Handle object structure similarly
        } else if (typeof category.description === 'string') {
          // Handle directly if description is a string
          descriptions = category.description.toLowerCase();
        }
  
        // Check if title, url, or descriptions include the search query
        if (
          category.title.toLowerCase().includes(searchLowerCase) ||
          category.url.toLowerCase().includes(searchLowerCase) ||
          descriptions.includes(searchLowerCase)
        ) {
          results.push({
            title: category.title,
            url: category.url,
            shorttitle: category.shorttitle,
            // Add other properties as needed
          });
        }
      } else {
        console.log('Category does not have necessary properties:', category);
      }
    });
  
    setSearchError(results.length === 0); // Set search error flag based on results
    setSearchResults(results);
  };
  ;
  
 

 
  
  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
    setSearchError(false);
    setPopupOpen(false); // Close the popup
    setSearchQuery(""); // Reset the search input value
    setSearchResults([]);
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div class="fix_header">
        <div className={`popup ${popupOpen ? "open" : ""}`}>
          <div className="popup-content">
            <button className="close-btn" onClick={handlePopupToggle}>
              X
            </button>
            <div>
              {/*<input
                          type="text"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          placeholder="Search..."
                        />
                        <button onClick={handleSearch}>Search</button>
                        <div>
                          
                         
                          {searchResults.length === 0
                            ? searchQuery.trim() !== "" && (
                                <p>No results found</p>
                              )
                            : searchResults.map((result) => (
                                <div key={result.title}>
                                  <NavLink
                                    to={result.url}
                                    onClick={handleShowNavbar}
                                  >
                                    {result.title}
                                  </NavLink>
                                </div>
                              ))}
                        </div> */}

              <div className="search-f1">
                <input
                  className="search-input"
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search..."
                />
                <button onClick={handleSearch}>Search</button>
                <div className="scroll-result">
                  {/* Display search results or "No results found" message */}
                  {/* {searchResults.length === 0 && searchError && (
                <p>{searchQuery} is No results found</p>
              )} */}

                  {searchResults.length === 0 &&
                    searchError &&
                    searchQuery.trim().length > 0 && <p>No results found</p>}

                  {/* {searchResults.length === 0 && handleSearch && searchError && (
                <p>No results found</p>
              )} */}

                  {searchResults.length > 0 &&
                    searchResults.map((result) => (
                      <div key={result.title}>
                        <NavLink
                          className="list-1"
                          to={result.url}
                          onClick={handleShowNavbar}
                        >
                          {result.title}
                        </NavLink>
                        {/* <p>{result.shorttitle.substring(0, 20)}</p> */}
                        <p>
                          {result.shorttitle.length > 50
                            ? result.shorttitle.substring(0, 50) + "..."
                            : result.shorttitle}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <header>
          {/* <SearchComponent/>  */}
          <div className="top-strip">
            <div class="wrapper">
              <div className="ts-1">
                <FontAwesomeIcon icon={faPhone} /> +1 (833) 646-3988
              </div>
              <div className="ts-1">
                {" "}
                <FontAwesomeIcon icon={faEnvelope} /> info@nfinity-global.com
              </div>
              <div className="ts-1">
                {" "}
                <NavLink onClick={handlePopupToggle}>
                  <FontAwesomeIcon icon={faSearch} />
                </NavLink>
              </div>
            </div>
          </div>
          <div class="wrapper">
      <div class="tz-t1">
        <div class="tz-r1">
          <div class="tz-c1">
          <NavLink to="/" ><div className='logoOuter'><img src={Logo} class="logo-div" alt="logo" width="170" /></div></NavLink>
           </div>
          <div class="tz-c1">
            <div class="nav-main">
              <div className="menu-icon nav-button22" onClick={handleShowNavbar}>  </div>
              <nav className={`nav-elements  ${showNavbar && "active"}`}>
                <ul>
                  <li onClick={scrollToTop}><NavLink to="/" onClick={handleShowNavbar} >Home</NavLink></li>
                  <li><b>Solutions</b>
                  <ul>
                    <li onClick={scrollToTop}><NavLink to="../solutions_audio_and_visual" onClick={handleShowNavbar}>Audio And Visual</NavLink></li>
                    <li onClick={scrollToTop}><NavLink to="../solutions_network_infrastructure" onClick={handleShowNavbar}>Network Infrastructure </NavLink></li>
                  </ul>
                  </li>
                  <li><b>Services</b>
                  <ul>
                  <li onClick={scrollToTop}><NavLink to="../our_services" onClick={handleShowNavbar}>Services Overview</NavLink></li>
                  <li onClick={scrollToTop}><NavLink to="../managed_services" onClick={handleShowNavbar}>Managed Services</NavLink>
                    <ul>
                      <li onClick={scrollToTop}><NavLink to="../it_managed_service_desk" onClick={handleShowNavbar}>IT Managed Service Desk</NavLink></li>
                      <li onClick={scrollToTop}><NavLink to="../on_site_remott_level_2_support" onClick={handleShowNavbar}>On-site & Remote Level 2 Support</NavLink></li>
                      <li onClick={scrollToTop}><NavLink to="../network_operation_247" onClick={handleShowNavbar}>24x7 Network Operation</NavLink></li>
                      <li onClick={scrollToTop}><NavLink to="../application_monitoring_247" onClick={handleShowNavbar}>24x7 Application Monitoring</NavLink></li>
                      <li onClick={scrollToTop}><NavLink to="../network_operations_center" onClick={handleShowNavbar}>NOC (Network Operations Center)</NavLink></li>
                      <li onClick={scrollToTop}><NavLink to="../avmanaged_services_247" onClick={handleShowNavbar}>24x7 AV Managed Services</NavLink></li>
                      <li onClick={scrollToTop}><NavLink to="../managed_network_infrastructure" onClick={handleShowNavbar}>Managed Network Infrastructure</NavLink></li>
                      <li onClick={scrollToTop}><NavLink to="../itas_set_seployment_services" onClick={handleShowNavbar}>IT Asset Deployment Services</NavLink></li>
                    </ul>
                    </li>
                    <li onClick={scrollToTop}><NavLink to="../audio_and_visual" onClick={handleShowNavbar} >Audio And Visual</NavLink>
                    <ul>
                    <li onClick={scrollToTop}><NavLink to="../refresh_upgrade" onClick={handleShowNavbar}> Refresh & Upgrade </NavLink> </li>
                    <li onClick={scrollToTop}><NavLink to="../white_glove_meeting_event_support" onClick={handleShowNavbar} > White-glove Meeting & Event Support</NavLink> </li>
                    <li onClick={scrollToTop}><NavLink to="../data_cabling" onClick={handleShowNavbar}> Data Cabling </NavLink> </li>
                    <li onClick={scrollToTop}><NavLink to="../preventative_maintenance" onClick={handleShowNavbar}> Preventative Maintenance </NavLink> </li>
                    <li onClick={scrollToTop}><NavLink to="../dispatched_services_two" onClick={handleShowNavbar}> Dispatched Services  </NavLink> </li>
                    </ul>
                    </li>
                    <li onClick={scrollToTop}><NavLink to="../network_infrastructure" onClick={handleShowNavbar}>Network Infrastructure</NavLink>
                      <ul>
                      <li onClick={scrollToTop}><NavLink to="../network_audit" onClick={handleShowNavbar}>Network Audit</NavLink></li>
                      <li onClick={scrollToTop}><NavLink to="../sdwan_configuration" onClick={handleShowNavbar}>SD-WAN Configuration</NavLink></li>
                      <li onClick={scrollToTop}><NavLink to="../network_infrastructure_dispatched_services" onClick={handleShowNavbar}>Dispatched Services</NavLink></li>
                      <li onClick={scrollToTop}><NavLink to="../commissioning_decommissioning" onClick={handleShowNavbar}>Commissioning/ Decommissioning</NavLink></li>
                      <li onClick={scrollToTop}><NavLink to="../network_installations_and_maintenance" onClick={handleShowNavbar}>Network Installations and Maintenance</NavLink></li>
                      </ul>
                    </li>
                    <li onClick={scrollToTop}><NavLink to="../engineering_and_iT_services" onClick={handleShowNavbar} >Engineering and IT Services</NavLink>
                    <ul>
                      <li onClick={scrollToTop}><NavLink to="../devOps" onClick={handleShowNavbar}>DevOps</NavLink></li>
                      <li onClick={scrollToTop}><NavLink to="../application_development" onClick={handleShowNavbar} >Application Support Service</NavLink></li>
                      <li onClick={scrollToTop}><NavLink to="../cloud_infrastructure" onClick={handleShowNavbar}>Cloud Infrastructure Services</NavLink></li>
                      <li onClick={scrollToTop}><NavLink to="../data_management_migration" onClick={handleShowNavbar}>Data Management & Migration Services</NavLink></li>
                      <li onClick={scrollToTop}><NavLink to="../cyber_security" onClick={handleShowNavbar}>Cyber Security Services</NavLink></li>
                    </ul>
                    </li>
                    {/* <li onClick={scrollToTop}><span onClick={handleShowNavbar}>Network Infrastructure </span></li> */}
                    <li onClick={scrollToTop}><NavLink to="../staffing_augumentation" onClick={handleShowNavbar}>Staff Augumentation</NavLink>
                    <ul>
                      <li onClick={scrollToTop}><NavLink to="../direct_hire_placement_services" onClick={handleShowNavbar}>Direct Hire Placement Services</NavLink></li>
                      <li onClick={scrollToTop}><NavLink to="../supplemental_staffing" onClick={handleShowNavbar}>Supplemental Staffing</NavLink></li>
                      <li onClick={scrollToTop}><NavLink to="../onsite_embedded_support" onClick={handleShowNavbar}>Onsite & Embedded Support</NavLink></li>
                      <li onClick={scrollToTop}><NavLink to="../professional_resources" onClick={handleShowNavbar} >Professional Resources (Contract Staffing/ Managed Staffing)</NavLink></li>
                    </ul>
                      </li>
                      </ul>
                  </li>
                  <li onClick={scrollToTop}><b>Customers &amp; Partners</b>
                    <ul>
                    <li onClick={scrollToTop}><NavLink to="our_customers" onClick={handleShowNavbar}>Our Customers</NavLink></li>
                    <li onClick={scrollToTop}><NavLink to="patners" onClick={handleShowNavbar}>Partners</NavLink></li>
                    <li onClick={scrollToTop}><NavLink to="client_experience" onClick={handleShowNavbar}>Client Experience</NavLink></li>
                    </ul>
                  </li>
                  <li onClick={scrollToTop}><b>Learn</b>
                    <ul>
                    <li onClick={scrollToTop}><NavLink to="careers" onClick={handleShowNavbar}>Careers</NavLink></li>
                    <li onClick={scrollToTop}><NavLink to="company_news" onClick={handleShowNavbar}>Company News</NavLink></li>
                    <li onClick={scrollToTop}><NavLink to="about_case_studies" onClick={handleShowNavbar}>Case Studies</NavLink></li>
                    </ul>
                  </li>
                  <li onClick={scrollToTop}><b>About Us</b>
                    <ul>
                    <li onClick={scrollToTop}><NavLink to="about_us" onClick={handleShowNavbar}>Company Overview</NavLink></li>
                    {/* <li onClick={scrollToTop}><NavLink to="OurFootPrints" onClick={handleShowNavbar}>Our Foot Prints</NavLink></li> */}
                    <li onClick={scrollToTop}><NavLink to="our_team" onClick={handleShowNavbar}>Our Team</NavLink></li>
                    <li onClick={scrollToTop}><NavLink to="our_board" onClick={handleShowNavbar}>Our Board</NavLink></li>
                    </ul>
                  </li>
                  <li onClick={scrollToTop}><NavLink to="contact_us" onClick={handleShowNavbar}>Contact Us</NavLink></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
        </header>
      </div>
    </div>
  );
}

export default Header;
