import React from "react";
import InnerShapeOne from "../images/inner-banner-shape.png";
//import CSLogo1img1 from "../images/casestudies-img-1.png";

function CareerPostOne() {
  return (
    <div className=" fade-in">
      <div className="inner-banner-wrap m-t-1">
        <div className="wrapper">
          <h1 className="banner-text-center">
            <b>Career</b> 
          </h1>
        </div>
        
      </div>
      <div className="inner-bg-2-main">
        <div className="inner-bg-2">
          <div className="wrapper-2">
            <h2>
            AV/VC Support Technician
            </h2>
            
            <h3>
            Overview:
            </h3>
            <p>
            We are seeking a person who will be responsible for the assistance in support and
maintenance of AV/VC hardware integrated within conference rooms. This person is
responsible for the delivery of customer success for our clients, ensuring conference rooms
have minimal down time and working to enhance the end user experience.
            </p>
            <p>
            Technology responsibilities include, but are not limited to, video conferencing, conference
room audio/video technologies, installation, and configuration of AV technologies.
            </p>
            <h3>Key Qualifications:</h3>
            <p>1. Operate and test daily functionality of all AV and integrated conference rooms.</p>
            <p>2. Perform routine testing and problem diagnosis for projectors, microphones, speakers, amplifiers &amp; LCD/Plasma Displays</p>
            <p>3. Perform preventative maintenance to resolve problems or identify a resolution to appropriate vendor or manufacturer.</p>
            <p>4. Troubleshoot AV/V problems last minute as required as well as non-critical situations.</p>
            <p>5. Assist with company meetings/functions that require use of AV infrastructure (audio, video conferencing, webcasting, visual display or projection, and control system (Crestron) operations.</p>
            <p>6. Interact with client Customer Help Desk ticketing system to respond to end user requests.</p>
            <p>7. Coordinates with client staff to determine AV basic infrastructure requirements for any new conference room or media design.</p>
            <p>8. Experience working with Senior Executive Staff</p>
            <p>9. Must have experience with troubleshooting videoconferencing, audio conferencing, and integrated A/V technologies in a large enterprise.</p>
            <p>10. Must have experience with Cisco, Polycom, Crestron, Extron, BiAmp, and corresponding hardware and software infrastructure.</p>
            <p>11. Familiarity with BlueJeans, Zoom, Skype for Business, WebEx.</p>
            <h3>Qualifications:</h3>
            <p>1. Comfortable working within Enterprise Google Suite (software and hardware)</p>
            <p>2. Experience installing and maintaining audio visual equipment in a corporate environment and an understanding of audio/video signal flow.</p>
            <p>3. Understanding of networking technology, video transmission over network</p>
            <p>4. Experience with Enterprise VC solutions (Preferably Google Meet but also Zoom, Microsoft Teams, Bluejeans, Cisco Telepresence etc.)-both hardware and software</p>
            <p>5. Experience working with Mac OS, Windows, and Chrome OS</p>
            <p>6. Experience setting up and operating AV equipment for live events (in-person and virtual)</p>
            <p>7. Experience working with Crestron equipment, audio DSP’s and Dante/AES microphone systems.</p>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default CareerPostOne;
