import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const ProductListTwo = () => {
  const products = [
    {
      id: 1,
      name: "Vijay Kammela",
      designation: "CEO",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      thumbnail: "./images/Vijay-Kammela.png",
    },
    {
      id: 2,
      name: "Madhav Rudraraju",
      designation: "Managing Director",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      thumbnail: "./images/Madhav.jpg",
    },
    {
      id: 3,
      name: "Sumit Shaw",
      designation: "VP - Managed Services",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      thumbnail: "images/sumith.png",
    },
    {
      id: 4,
      name: "Anil Myneni",
      designation: "AVP - Managed Services",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      thumbnail: "images/Anil.jpg",
    },
    {
      id: 5,
      name: "Krishna Gogineni",
      designation: "AVP - Engineering Services",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      thumbnail: "images/Krishna.jpg",
    },
    {
      id: 6,
      name: "Shanthi Rudraraju",
      designation: "Director - inance",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      thumbnail: "images/Shanthi.jpg",
    },
    {
      id: 7,
      name: "Mindy Vu",
      designation: "Director - Operations",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      thumbnail: "images/Mindy.png",
    },
    {
      id: 8,
      name: "Aparna Janapati",
      designation: "HR Manager/Talent Acquisition",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      thumbnail: "images/aparna.png",
    },
    {
      id: 9,
      name: "Sandhya Rudraraju",
      designation: "Head - Engagement & Culture",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      thumbnail: "images/Sandhya.jpg",
    }
  ];

  const [selectedProduct, setSelectedProduct] = useState(null);

  const openPopup = (product) => {
    setSelectedProduct(product);
  };

  const closePopup = () => {
    setSelectedProduct(null);
  };

  return (
    <div className="container mt-5">
      <h1>Product List</h1>
      <div className="OurTeamExperience">
        {products.map((product) => (
          <div key={product.id} className="OurTeam-border-1">
            <div className="OurTeam-border-1-1">
              <img
                src={product.thumbnail}
                className="card-img-top"
                alt={product.name}
              />
            </div>
            <div className="OurTeam-border-1-2">
              <h3>{product.name}</h3>
              <p>{product.designation}</p>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => openPopup(product)}
              >
                Read more
              </button>
            </div>
          </div>
        ))}
      </div>

      {selectedProduct && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{selectedProduct.name}</h5>
                <button type="button" className="close" onClick={closePopup}>
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <img
                  src={selectedProduct.thumbnail}
                  className=""
                  alt={selectedProduct.name}
                />
                <p>{selectedProduct.description}</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closePopup}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductListTwo;
