import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SearchComponent = () => {
  const [keyword, setKeyword] = useState('');
  const [matchedPages, setMatchedPages] = useState([]);
  const navigate = useNavigate();

  // Define keywords and associated pages
  const keywordPages = {
    keyword1: ['page1', 'page2'],
    Audio: ['SolutionsAudioandVisual', 'SolutionsNetworkInfrastructure'],
    // Add more keywords and their associated pages here
  };

  const handleSearch = () => {
    // Check if the entered keyword matches any keyword in the mapping
    const matchedPages = keywordPages[keyword];
    if (matchedPages) {
      // If matched, update state with the matched pages
      setMatchedPages(matchedPages);
    } else {
      // If not matched, show an alert
      alert('No pages found for the entered keyword!');
    }
  };

  const handlePageClick = (page) => {
    // Navigate to the selected page
    navigate(`/${page}`);
  };

  return (
    <div style={{marginTop: '100px'}}>
      <input 
        type="text" 
        placeholder="Enter keyword" 
        value={keyword} 
        onChange={(e) => setKeyword(e.target.value)} 
      />
      <button onClick={handleSearch}>Search</button>
      {/* Popup for displaying matched pages */}
      {matchedPages.length > 0 && (
        <div 
          style={{ 
            position: 'absolute', 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)', 
            backgroundColor: 'white', 
            padding: '20px', 
            border: '1px solid #ccc' 
          }}
        >
          <p>Choose a page:</p>
          <ul>
            {matchedPages.map((page, index) => (
              <li key={index}>
                <button onClick={() => handlePageClick(page)}>{page}</button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchComponent;
