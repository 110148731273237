import React from "react";
import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

import bannerImg1 from "../images/n-banner-1.jpg";
import bannerImg2 from "../images/n-banner-2.jpg";
import bannerImg3 from "../images/n-banner-3.jpg";
import { NavLink } from "react-router-dom";
function ControlledCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
          <img src={bannerImg1}></img>
          <Carousel.Caption>
            <h3>
            Elevate Your Enterprise with Nfinity Global - Specializing in Profitable Operations and Strategic Global Expansions.
            </h3>
            <NavLink
              to="../about_us"
              onClick={scrollToTop}
              className="submit-1-blue m-t-2"
            >
              Learn More
            </NavLink>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={bannerImg2}></img>
          <Carousel.Caption>
            <h3 style={{'color' : '#fff' }}>
            Discover Global Opportunities with Nfinity Global - Engineering Excellence and Managed Service Expertise at Your Fingertips.
            </h3>
            <NavLink
              to="../about_us"
              onClick={scrollToTop}
              className="submit-1-blue m-t-2"
            >
              Learn More
            </NavLink>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img src={bannerImg3}></img>
          <Carousel.Caption>
          <h3 style={{'color' : '#fff' }}>
           Drive Your Market Expansion with Confidence - Experience Profitable Growth with Nfinity Global&#39;s Tailored Engineering Solutions.
            </h3>
            <NavLink
              to="../about_us"
              onClick={scrollToTop}
              className="submit-1-blue m-t-2"
            >
              Learn More
            </NavLink>
          </Carousel.Caption>
        </Carousel.Item>


      </Carousel>
    </div>
  );
}

export default ControlledCarousel;
