// src/components/CareerDetailsTwo.js
import React, { useState , useEffect } from "react";
import { useParams } from "react-router-dom";
 //import jobData from "./SepareteJobData";
import InnerShapeOne from "../images/inner-banner-shape.png";
import ContactPatch3 from "../images/contactus-patch-3.png";
import Timestamp from "react-timestamp"

const SeparateCareerDetailsTwo = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [otherinformation, setOtherinformation] = useState([]);
  const [jobRole, setJobRole] = useState("");
  const [resume, setResume] = useState(null);

  const [careerJobId , setJobId] = useState("");

  const [job, setJob] = useState(null);

  
  const handlechange = (e, name) => {
    if (name === "name") {
      setName(e.target.value);
    } else if (name === "email") {
      setEmail(e.target.value);
    } else if (name === "phone") {
      setPhone(e.target.value);
    } else if (name === "otherinformation") {
      setOtherinformation(e.target.value);
    }
  };
 
  
  

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  
  //   // Function to upload file
  //   const uploadFile = async () => {
  //     try {
  //       const formData = new FormData();
  //       formData.append("resumefile", resume);
  //       formData.append("job_id", careerJobId);
  
  //       const response = await fetch("http://nfinity.psmprojects.net/career-file-upload.php", {
  //         method: "POST",
  //         body: formData,
  //       });
  
  //       if (response.ok) {
  //         const data = await response.json();
  //         console.log("File Upload Response:", data);
  
  //         if (data && data.error) {
  //           console.error("Server Error:", data.error);
  //           return null;
  //         } else {
  //           console.log("Uploaded file name:", data.filename);
  //           return data.filename;
  //         }
  //       } else {
  //         const errorText = await response.text();
  //         console.error("Server response:", errorText);
  //         throw new Error('Failed to upload file');
  //       }
  //     } catch (error) {
  //       console.error("Upload error:", error);
  //       return null;
  //     }
  //   };
  
  //   // Upload the file and get the filename
  //   const filename = await uploadFile();
  
  //   // If file upload fails, alert the user and stop execution
  //   // if (!filename) {
  //   //   alert("File upload failed. Please try again.");
  //   //   return;
  //   // }

  //   console.log("filename" , filename)
  
  //   // Create the data object for form submission
  //   const dataObj = {
  //     name: name,
  //     email: email,
  //     phone: phone,
  //     otherinformation: otherinformation,
  //     job_role: jobRole,
  //     job_id: careerJobId,
  //    // resume: filename,
  //     resumefile:filename
  //      // Include the filename in the data object
  //   };
  //   console.log("dataObj", dataObj.resumefile);
  
  //   // Function to submit form data
  //   const postData = async () => {
  //     try {
  //       const response = await fetch("http://nfinity.psmprojects.net/career-form.php", {
  //         method: "POST",
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify(dataObj),
  //       });
  
  //       if (response.ok === true) {
  //         const data1 = await response.json();
  //         console.log("response from post", data1);
  //         alert("Form submitted successfully!");
  //       } else {
  //         const errorText = await response.text();
  //         console.error("Server response:", errorText);
  //         throw new Error('Failed to upload');
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };
  
  //   // Submit the form data
  //   postData();
  // };
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Function to upload file
    const uploadFile = async () => {
      try {
        const formData = new FormData();
  
        // Generate a timestamp
        const timestamp = new Date().toISOString().replace(/[:.-]/g, "");
  
        // Modify the file name to include the timestamp
        const fileWithTimestamp = new File(
          [resume],
          `${timestamp}_${resume.name}`,
          { type: resume.type }
        );
  
        formData.append("resumefile", fileWithTimestamp);
        formData.append("job_id", careerJobId);
  
        const response = await fetch("http://nfinity.psmprojects.net/career-file-upload.php", {
          method: "POST",
          body: formData,
        });
  
        if (response.ok) {
          const data = await response.json();
          console.log("File Upload Response:", data);
  
          if (data && data.error) {
            console.error("Server Error:", data.error);
            return null;
          } else {
            console.log("Uploaded file name:", data.filename);
            return data.filename;
          }
        } else {
          const errorText = await response.text();
          console.error("Server response:", errorText);
          throw new Error('Failed to upload file');
        }
      } catch (error) {
        console.error("Upload error:", error);
        return null;
      }
    };
  
    // Upload the file and get the filename
    const filename = await uploadFile();
  
    // If file upload fails, alert the user and stop execution
    if (!filename) {
      alert("File upload failed. Please try again.");
      return;
    }
  
    // Create the data object for form submission
    const dataObj = {
      name: name,
      email: email,
      phone: phone,
      otherinformation: otherinformation,
      job_role: jobRole,
      job_id: careerJobId,
      resumefile: filename // Include the filename in the data object
    };
    console.log("dataObj", dataObj);
  
    // Function to submit form data
    const postData = async () => {
      try {
        const response = await fetch("http://nfinity.psmprojects.net/career-form.php", {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(dataObj),
        });
  
        if (response.ok === true) {
          const data1 = await response.json();
          console.log("response from post", data1);
          alert("Form submitted successfully!");
        } else {
          const errorText = await response.text();
          console.error("Server response:", errorText);
          throw new Error('Failed to upload');
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    // Submit the form data
    postData();
  };
  

  const onChangeFileUpload = async (e) => {
    const file = e.target.files[0]; // Get the file from the input field
    setResume(file);
  };

  
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  // http://nfinity.psmprojects.net/careerdetail.php/1
  const { id } = useParams();
  const jobId = parseInt(id);

  useEffect(() => {

    const fetchCarrerData = async() => {
      try{
        const response = await fetch(` http://nfinity.psmprojects.net/careerdetail.php/${jobId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jobData = await response.json();
        if (jobData) {
          setJob(jobData); // Set the fetched job details
         // setJobRole(jobData.job_title); // Update job role
        }
      }
      catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchCarrerData();
  },[jobId])

  
  
 // const job = careerDetailsData.find((job) => job.id === jobId);



  useEffect(() => {
    if (job) {
      setJobRole(job.job_title); // Update job role when job changes
      setJobId(job.id); 
    }
  }, [job]);

  if (!job) {
    return <div>Job not found</div>;
  }

  return (
    <div>
      <div className="inner-banner-wrap m-t-1 Careers-banner">
        <div className="wrapper">
          <h1 className="banner-text-center">
            <b>Careers</b>
          </h1>
        </div>
      </div>

      <div className="inner-page-bg-1">
        <div className="wrapper">
          <h1>{job.job_title}</h1>
          <p>
            <strong>Company:</strong> {job.company_name}
          </p>
          <p>
            <strong>Location:</strong> {job.location}
          </p>
          <p>
            <strong>Description:</strong> {job.description}
          </p>
          <p>
            <strong>Requirements:</strong>
          </p>
          <div className="bullet-1">
            {/* <ul>
              {job.requirements.map((requirement, index) => (
                <li key={index}>{requirement}</li>
              ))}
            </ul> */}
            <ul>
              {Array.isArray(job.requirements) && job.requirements.map((requirement, index) => (
                <li key={index}>{requirement}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div class="strip-100">
        <img src={ContactPatch3} alt="ContactPatch2" />
      </div>
      <div className="form-styles">
        <div className="form-sec-main ">
          <h2 className="w-100">Apply For the {job.job_title} Post:</h2>

          <form onSubmit={handleSubmit} encType="multipart/form-data">
           <div className="form-sec-1">
              <b>Job Role*</b>
              <input
                className="text-1"
                value={job.job_title}
                //type="hidden"
                name="jobRole"
                onChange={(e) => setJobRole(e.target.value)}
              />


              <input
                value={job.id}
                type="hidden"
                name="careerJobId"
                onChange={(e) => setJobId(e.target.value)}
              />


            </div>
            <div className="form-sec-1">
              <b>Name*</b>
              <input
                className="text-1"
                type="text"
                value={name}
                name="name"
                id="name"
                onChange={(e) => handlechange(e, "name")}
              />
            </div>
            <div className="form-sec-1">
              <b>Email*</b>
              <input
                className="text-1"
                type="text"
                value={email}
                name="email"
                id="email"
                onChange={(e) => handlechange(e, "email")}
              />
            </div>
            <div className="form-sec-1">
              <b>Phone No.*</b>

              <input
                className="text-1"
                type="text"
                value={phone}
                name="phone"
                id="phone"
                onChange={(e) => handlechange(e, "phone")}
              />
            </div>

            <div className="form-sec-1">
              <b>Upload Resume*</b>
              <input
                type="file"
                className="text-1"
                name="resume"
                onChange={onChangeFileUpload}
                placeholder="Subject"
              />
            </div>
            <div className="form-sec-1 w-100">
              <b>Other Information</b>
              <textarea
                className="textarea-1"
                placeholder="Message"
                value={otherinformation}
                name="otherinformation"
                id="otherinformation"
                onChange={(e) => handlechange(e, "otherinformation")}
              ></textarea>
            </div>
            <div className="form-sec-1 w-100 text-center">
              <button type="submit" value="Submit" className="submit-1">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SeparateCareerDetailsTwo;
