import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


import whywebestdata from './whywebestdata'
const styles = {
  whywebest:{
      color:'red',
      border: '1px solid #ff0000',

  },
  
  headingStle1:{
    
},

  imgstyle:{
      width: '200px'
  }
}
const whyBestCard = whywebestdata.map((aboutObj)=>{
  const {id, title,url} = aboutObj;
  return(
      <div className='border-1 white-bg-1a' key={id}>
         <img src={url} alt={title}/>
          <h3 style={styles.headingStle1}>{title}</h3>
          {/*<h3 style={styles.headingStle1}>{title}</h3>*/}
         
      </div>
  )
})

export default function WeareBestSlider() {
  var bestslider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    arrows: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
         
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  return (
    <div className='wrapper Best-sec'>
      
    <Slider {...bestslider}>
      {whyBestCard}
    </Slider>
    </div>
  );
}



  

