import React from "react";
import InnerShapeOne from "../images/inner-banner-shape.png";
import CSLogo1img1 from "../images/casestudies-img-1.png";

function CaseStudyTwo() {
  return (
    <div className="fade-in">
      <div className="inner-banner-wrap m-t-1">
        <div className="wrapper">
          <h1 className="banner-text-center">
            <b>Case Studies</b> Inner
          </h1>
        </div>
        
      </div>
      <div className="inner-bg-2-main">
        <div className="inner-bg-2">
          <div className="wrapper-2">
            <h2>Case Study – Global OEM Telco Company</h2>
            <img src={CSLogo1img1} alt={CSLogo1img1} />

            <h4>Problem Statement</h4>
            <ul>
              <li>
                Visibility to Network issues across CORE and Periphery Network.
              </li>
              <li>
                Monitoring 30 + Locations with Data Centers and Testing Labs.
              </li>
              <li>Frequent Outages Impacting Production.</li>
              <li>The outage Handling process was missing.</li>
              <li>Application Outages</li>
              <li>Skill Gaps</li>
            </ul>
            <h4>Client Brief</h4>
            <ul>
              <li>Revenue – 4+ Billion USD</li>
              <li>Industry - Telco</li>
              <li>Employees - 10000</li>
              <li>Customers – 20000+</li>
            </ul>
            <h5>Infrastructure:</h5>
            <ul>
              <li>Juniper &amp; Cisco Routers</li>
              <li>Cisco Switches &amp; Access Points.</li>
              <li>Cisco Wireless LAN Controllers</li>
              <li>Microsoft Azure</li>
              <li>Salesforce Service Cloud</li>
              <li>Fortinet Security Products</li>
            </ul>
            <h4>Solution</h4>
            <h5>Our Approach:</h5>
            <p>
              All Critical Network Elements & Critical Alerts were Identified &
              on-boarded to Platform.
            </p>
            <p>
              Threshold KPIs were identified and a Co-orelation engine built
            </p>
            <p>
              ITIL-Based framework for Incident, Problem and Change Management.
              (Runbook and Mop)
            </p>
            <p>
              Technology and Soft Skills Training for Team at regular intervals.
            </p>

            <h4>Results</h4>
            <ul>
              <li>Mean Time to Notify Improved by 80% in 2 Quarters.</li>
              <li>Mean Time to Restore improved by 75%</li>
              <li>
                Significant reduction in escalation from Business functions.
              </li>
              <li>
                99% success Rate on Change Execution resulting in stable
                Network.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CaseStudyTwo;
