import React from "react";
import Revolutionize from "./Revolutionize";
import { NavLink } from "react-router-dom";
import servicedata from "./servicedata.js";

function NetworkInstallationsandMaintenance() {
  const data = servicedata.NetworkInstallationsandMaintenance;
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="fade-in">
      <div className="inner-banner-wrap m-t-1">
        <div className="wrapper">
          <h1 className="banner-text-center">{data.title}</h1>
          <p>{data.shorttitle}</p>
        </div>
      </div>
      <div className="inner-services-r-bg">
        <div className="wrapper">
          {data.description.content.map((ArchitectureDesignSetup, index) => (
            <p key={index}>{ArchitectureDesignSetup}</p>
          ))}
          
        </div>
      </div>
    </div>
  );
}
export default NetworkInstallationsandMaintenance;


