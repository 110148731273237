import React from 'react'
import InnerShapeOne from '../images/inner-banner-shape.png';
import CSLogo1img1 from '../images/casestudies-img-1.png';

function CaseStudyThree() {
  return (
    <div className='fade-in'>
    <div className='inner-banner-wrap m-t-1'>
      <div className='wrapper'>
        <h1 className='banner-text-center'><b>Case Studies</b>  Inner</h1>
      </div>
       
    </div>
<div className='inner-bg-2-main'>
<div className='inner-bg-2'>
      <div className='wrapper-2'>
        <h2>hermo Fisher</h2>
        <img src={CSLogo1img1} alt={CSLogo1img1} />

<h4>Application</h4>
<p>RMP - Twilio - International Messaging</p>
<h4>Problem of our customer we were addressing</h4>
<p>To enguage and retain the patients Sending messages, Making calls internationally on timely basis and based on user specific language</p>
<h4>Our solution</h4>
<p>International messaging is a micro service application developed to send scheduled and instant messages to the 
users all over the world. The messaging application sends messages based on the country and language. The message will be delivered according to the local time and the language proffered by the user. This is developed as a service as to be used across all the applications in Acurian.</p>
<h4>Outcome &amp; benefits</h4>
<p>This micro service application is developed in such way as it can be used or coupled with any other application using APIs provided</p>


<h4>Application</h4>
<p>RMP - Hyperwallet -Payment provider Services </p>
<h4>Problem of our customer we were addressing</h4>
<p>Making payments to all patients by card, by cheque, to bank account for their participation in studies</p>
<h4>Our solution</h4>
<p>Payment services  is a micro service application used to pay users around the globe. As part of retention manager Acurian pays the users for participating in their studies. To pay the user it follows different ways of payment and pays the users around the globe. This payment services are used pay the users by card, by cheque, to bank account. This payment module is implemented using a third party payment provider called hyperwallet</p>
<h4>Outcome &amp; benefits</h4>
<p>This micro service application is developed in such way as it can be used or coupled with any other application using APIs provided</p>

<h4>Application</h4>
<p>Acurian Services(AS)</p>
<h4>Problem of our customer we were addressing</h4>
<p>Tedious and tuff to handle all the Micro service applications and maintain all APIs for house keeping</p>
<h4>Our solution</h4>
<p>Acurian Web services the project to make all the common and housekeeping transactions to be at one place and to make all the transactions accountable. This application has the ability add services, applications and clients. 
After adding a service, this has the facility to assign this service to particular application and particular client. In this application all the service request logs are registered and can be viewed</p>
<h4>Outcome &amp; benefits</h4>
<p>Easy to handle all Microservice applications as this is a single house keeping app where all APPs and APIs and managed and configured in one place.
In this now the client can see all the logs for all requests and responces in one place.
Can configure Clients, Users and can controll the access easily</p>


<h4>Application</h4>
<p>Screener Builder</p>
<h4>Problem of our customer we were addressing</h4>
<p>Place needed to build screeners where bussiness users can come and configure the study on their own </p>
<h4>Our solution</h4>
<p>Screener builder is an application to design a screeners which is used to screen patients who are willing 
to participate in any study. A study means any disease or illness like diabetes, asthma etc. Screener builder is used by the business analysts and SMEs to prepare a questionnaire which are dynamic in nature. For example if a question is asked and there are 4 options to that question. So using screener builder business analysts or SMEs will design questioner such that what will be next question or next action to happen based on the answer given by patients. Screener builder has option to create study, question builder and logic builder which decides the flow of the screener</p>
<h4>Outcome &amp; benefits</h4>
<p>Any study can be easily configured and managed</p>


<h4>Application</h4>
<p>Screener</p>
<h4>Problem of our customer we were addressing</h4>
<p>Get the Patient Data, Qualify and segregate them for a study according to the answers of the given question. Also help them scheduling site visits and booking appointments. Have 24 hours and 15 minutes sms / email reminders. </p>
<h4>Our solution</h4>
<p>Pre-Screener Application</p>
<h4>Outcome &amp; benefits</h4>
<p>Millions of Patients have take the Screener and helped with the studies. A very nice UI and smooth Screener. </p>
      </div>
    </div>
</div>
</div>
  )
}
export default CaseStudyThree
