import React from "react";
import InnerShapeOne from "../images/inner-banner-shape.png";
import ManagedServices from "../images/managed-services.png";
import ItDigitalTransfer from "../images/it-digital-transfer.png";
import AudioVisual from "../images/audio-visual-collaboration.png";
import StaffingServices from "../images/staffing-services.png";
import { NavLink } from "react-router-dom";
import EngineeringandITServices from "../images/EngineeringandITServices.png";

import ManagedServicesImg from "../images/ManagedServices-H-1.png";
import AudioandVisualImg from "../images/AudioandVisual-H-1.png";
import NetworkInfrastructureImg from "../images/NetworkInfrastructure-H-1.png";
import NetworkCloudandSecurityImg from "../images/NetworkCloudandSecurity-H-1.png";
import StaffAugmentationImg from "../images/StaffAugmentation-H-1.png";

function OurServices() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className="inner-banner-wrap OurServices-banner-bg m-t-1 ">
        <div className="wrapper">
          <h1 className="banner-text-center">Services</h1>
          <p>
          Unlock Innovation and Efficiency - Propel your business with our comprehensive IT services, delivering expert management, engineering, and enhancement.
          </p>
        </div>
      </div>

      <div className="inner-page-bg-1">
        <div className="services-wrap-bg">
          <div className="services-wrap">
            <div className="services-wrap-1">
              <h2>Managed Services</h2>
              <p>
                At Nfinity Global, we understand the critical role that a
                reliable and efficient IT infrastructure plays in the success of
                your business. Our IT Managed Services are designed to provide
                comprehensive support and proactive management of your
                technology assets, allowing you to focus on your core business
                objectives.
              </p>

              <NavLink
                to="../managed_services"
                onClick={scrollToTop}
                className="submit-1 m-t-2"
              >
                Learn More
              </NavLink>
            </div>
            <div className="services-wrap-2">
              <img src={ManagedServicesImg} alt="ManagedServicesImg" />
            </div>
          </div>
        </div>

        <div className="services-wrap-bg">
          <div className="services-wrap">
            <div className="services-wrap-1">
              <h2>Audio and Visual</h2>
              <p>
              At Nfinity Global, we specialize in delivering exceptional Audio Video Solutions and Build services that transform spaces into immersive environments and elevate communication experiences. Whether you're looking to enhance your conference rooms, auditoriums, retail spaces, or any other environment, our team of AV experts is here to bring your vision to life.
              </p>

              <NavLink
                to="../audio_and_visual"
                onClick={scrollToTop}
                className="submit-1 m-t-2"
              >
                Learn More
              </NavLink>
            </div>
            <div className="services-wrap-2">
              <img src={AudioandVisualImg} alt="ManagedServicesImg" />
            </div>
          </div>
        </div>

        <div className="services-wrap-bg">
          <div className="services-wrap">
            <div className="services-wrap-1">
              <h2>Network Infrastructure</h2>
              <p>
              At Nfinity Global, we specialize in providing comprehensive solutions for all your network needs. Our team of experts is dedicated to delivering top-notch services that encompass every stage of the network lifecycle. From planning and building to rolling out, upgrading, operating, maintaining, and transferring, we've got you covered.
              </p>

              <NavLink
                to="../network_infrastructure"
                onClick={scrollToTop}
                className="submit-1 m-t-2"
              >
                Learn More
              </NavLink>
            </div>
            <div className="services-wrap-2">
              <img src={NetworkInfrastructureImg} alt="NetworkInfrastructure" />
            </div>
          </div>
        </div>



        <div className="services-wrap-bg">
          <div className="services-wrap">
            <div className="services-wrap-1">
              <h2>Engineering and IT Services</h2>
              <p>
              Nfinity offers versatile IT Engineering Services, specializing in DevOps, Cloud Infrastructure, and Cyber Security. Our Application Support ensures seamless operations, while Data Management & Migration guarantees secure handling. Committed to a holistic approach, we build resilient IT infrastructures for client success in the digital landscape.
              </p>

              <NavLink
                to="../engineering_and_iT_services"
                onClick={scrollToTop}
                className="submit-1 m-t-2"
              >
                Learn More
              </NavLink>
            </div>
            <div className="services-wrap-2">
              <img src={NetworkCloudandSecurityImg} alt="NetworkInfrastructure" />
            </div>
          </div>
        </div>


        <div className="services-wrap-bg">
          <div className="services-wrap">
            <div className="services-wrap-1">
              <h2>Staff Augmentation</h2>
              <p>
              At Nfinity Global, we understand that having the right talent is crucial for the success of your business. Our Staff Augmentation services provide you with the flexibility and expertise you need to scale your workforce and meet your project demands. Whether you're looking for short-term support or long-term collaboration, we have the resources to augment your team with skilled professionals who seamlessly integrate into your organization.
              </p>

              <NavLink
                to="../staffing_augumentation"
                onClick={scrollToTop}
                className="submit-1 m-t-2"
              >
                Learn More
              </NavLink>
            </div>
            <div className="services-wrap-2">
              <img src={StaffAugmentationImg} alt="NetworkInfrastructure" />
            </div>
          </div>
        </div>


      </div>
    </div>
  );
}

export default OurServices;
