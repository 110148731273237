import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import '../AboutSlider.css'


import data from './clients'
const styles = {
  headingStle1:{
      color:'red',
      fontsize:'10px'

  },
  imgstyle:{
      width: '200px'
  }
}
const aboutCard = data.map((aboutObj)=>{
  const {id, title,url} = aboutObj;
  return(
      <div key={id}>
          <h3 style={styles.headingStle1}>{title}</h3>
          <img src={url} alt={title}/>
      </div>
  )
})


export default function ClientsSlider() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 4000,
    slidesToShow: 4,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
  autoplaySpeed: 12000,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 2,
        slidesToScroll: 1,
        pauseOnHover: false,
      }
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
      }
    }
  ]
  };
  return (
    <div className='wrappe clientSlideSec'>
      <div></div>
    <Slider {...settings}>
      {aboutCard}
    </Slider>
    </div>
  );
}



  

