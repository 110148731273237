
import React from 'react'
import InnerShapeOne from '../images/inner-banner-shape.png';

function NMSP() {
  return (
    <div>
    <div className='inner-banner-wrap m-t-1'>
      <div className='wrapper'>
        <h1 className='banner-text-center'><b>NMSP </b></h1>
      </div>
       
    </div>

    <div className='inner-page-bg-1'>
    <div className='wrapper'>
  <div className='heading-center-2'>
      <h2>NM <b>SP</b> </h2>
      <p>Content wll be posted soon..</p>
      </div>
      <div className='services-video-1'>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/XZrckLYqdys?si=z8mFfYSJBzG5h0uO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>

  </div>

    </div>
    
    
    
     

</div>
  )
}

export default NMSP
