import React from 'react'
import InnerShapeOne from '../images/inner-banner-shape.png';
import NoProfile from '../images/profile-1.jpg';

function ClientExperience() {
  return (
    <div className=' fade-in'>
    <div className='inner-banner-wrap m-t-1 OurCustomers-banner'>
      <div className='wrapper'>
        <h1 className='banner-text-center'><b>Client</b> Experience</h1>
      </div>
       
    </div>
<div className='inner-bg-2-main'>

<div className='inner-bg-2'>
      <div className='wrapper'>

        <div className='ClientExperience'>
        <div className='Client-border-1'>
          <div className='Client-border-1-1'><img src={NoProfile} alt='NoProfile'></img></div>
          <div className='Client-border-1-2'>
            <h3>Remote Infrastructure Support Team</h3>
            <p>I am writing to express my sincere appreciation and commendation for the exemplary services provided by the Remote Infrastructure Support Team at NFINITY. I have had the privilege of witnessing firsthand the outstanding dedication, expertise, and professionalism demonstrated by this team.</p>
            <p>The Remote Infrastructure Support Team has consistently demonstrated an unwavering commitment to ensuring the seamless operation of our critical systems and infrastructure. Their proactive approach to identifying potential issues, swift response to incidents, and timely resolution of challenges have been instrumental in maintaining our organization&#39;s operational efficiency and minimizing disruptions.</p>
            <h6>Customer Name - Customer Designation</h6>
          </div>

          
        </div>


        <div className='Client-border-1'>
          <div className='Client-border-1-1'><img src={NoProfile} alt='NoProfile'></img></div>
          <div className='Client-border-1-2'>
            <h3>IT Service Desk Support</h3>
            <p>The team&#39;s proficiency in managing and prioritizing a diverse range of IT requests, from
minor issues to critical system outages, has greatly contributed to the overall efficiency and
productivity of our workforce. Their ability to communicate effectively, providing timely
updates and guidance, has been crucial in assisting our employees and facilitating a smooth
resolution process.</p>
            <p>IT Service Desk Support team at NFINITY has consistently demonstrated exceptional
expertise, professionalism, and a customer-centric approach. Their dedication to delivering
high-quality service and their commitment to continuous improvement make them a vital
component of our organization&#39;s success.</p>
<h6>Customer Name - Customer Designation</h6>
          </div>
        </div>


        <div className='Client-border-1'>
          <div className='Client-border-1-1'><img src={NoProfile} alt='NoProfile'></img></div>
          <div className='Client-border-1-2'>
            <h3>Network Operations Center (NOC)</h3>
            <p>The NOC team has consistently demonstrated an unparalleled commitment to ensuring the
stability and security of our network infrastructure. Their proactive monitoring, rapid
response to incidents, and adept troubleshooting have been pivotal in maintaining seamless
network operations and minimizing potential disruptions to our business processes.</p>
            <p>The team&#39;s depth of technical knowledge and their ability to swiftly and effectively address
complex network issues have been instrumental in upholding the high standards of service we expect. Their proactive approach to identifying and mitigating potential network risks
before they escalate showcases their proactive mindset and dedication to our organization&#39;s
operational excellence.</p>
            <h6>Customer Name - Customer Designation</h6>
          </div>
        </div>


        <div className='Client-border-1'>
          <div className='Client-border-1-1'><img src={NoProfile} alt='NoProfile'></img></div>
          <div className='Client-border-1-2'>
            <h3>Application Support Services</h3>
            <p>Application Support Services team&#39;s exceptional customer service and effective
collaboration with our internal teams have been invaluable. Their ability to communicate
technical information in a clear and understandable manner, coupled with their dedication
to resolving issues promptly, has significantly contributed to our organization&#39;s success.</p>
            <p>In summary, the Application Support Services team at NFINITY has consistently exhibited a
high level of expertise, dedication, and customer-centric service. Their proactive efforts and
commitment to ensuring the optimal performance of our applications have greatly
contributed to our organization&#39;s success.</p>
            <h6>Customer Name - Customer Designation</h6>
          </div>
        </div>

        <div className='Client-border-1'>
          <div className='Client-border-1-1'><img src={NoProfile} alt='NoProfile'></img></div>
          <div className='Client-border-1-2'>
            <h3>Security Operations Center (SOC)</h3>
            <p>I highly commend the Security Operations Center (SOC) team at NFINITY Their proactive
approach to threat detection, swift incident response, and expertise in analyzing security
events are vital in fortifying our organization against cyber threats. Their effective
communication and collaboration are instrumental in safeguarding our sensitive data. I
confidently recommend the SOC team for their exceptional dedication to our organization&#39;s
cybersecurity.</p>
            
            <h6>Customer Name - Customer Designation</h6>
          </div>
        </div>

        <div className='Client-border-1'>
          <div className='Client-border-1-1'><img src={NoProfile} alt='NoProfile'></img></div>
          <div className='Client-border-1-2'>
            <h3>Platform Engineering (NICE)</h3>
            <p>I am thoroughly impressed with the Platform Engineering Development team at NFINITY.
Their exceptional technical expertise and creative problem-solving have resulted in robust,
scalable platforms that drive our business. They possess a keen understanding of our needs
and consistently deliver top-quality solutions. I highly recommend the Platform Engineering
Development team for their commitment to excellence.</p>
            <h6>Customer Name - Customer Designation</h6>
          </div>
        </div>




        



        </div>
     


</div>

    </div>
</div>
   
   
   
    
    
     

</div>
  )
}

export default ClientExperience
