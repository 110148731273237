import React from "react";
import Revolutionize from "./Revolutionize";
import { NavLink } from "react-router-dom";
import servicedata from "./servicedata.js";

function DataManagementMigration() {
  const data = servicedata.DataManagementMigration;
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="fade-in">
      <div className="inner-banner-wrap m-t-1">
        <div className="wrapper">
          <h1 className="banner-text-center">{data.title}</h1>
          <p>{data.shorttitle}</p>
        </div>
      </div>
      <div className="inner-services-r-bg">
        <div className="wrapper">
        <p>{data.description.content}</p>
        
        
          <h4>Data Migration:</h4>
          <h5>Data Architecture and Modeling:</h5>
          {data.description.datamanagment.DAM.map((DataManagementMigration, index) => (
            <p key={index}>{DataManagementMigration}</p>
          ))}
        <h5>Data Governance and Quality:</h5>
          {data.description.datamanagment.DGQ.map((DataManagementMigration, index) => (
            <p key={index}>{DataManagementMigration}</p>
          ))}
        <h5>Master Data Management:</h5>
          {data.description.datamanagment.MDM.map((DataManagementMigration, index) => (
            <p key={index}>{DataManagementMigration}</p>
          ))}
        <h5>Data Security and Privacy:</h5>
          {data.description.datamanagment.DSP.map((DataManagementMigration, index) => (
            <p key={index}>{DataManagementMigration}</p>
          ))}
          <hr/>
          <h4>Data Migration:</h4>
          <h5>Migration Planning and Strategy:</h5>
          {data.description.datamigration.MPs.map((DataManagementMigration, index) => (
            <p key={index}>{DataManagementMigration}</p>
          ))}
          <h5>ETL (Extract, Transform, Load) Services:</h5>
          {data.description.datamigration.ETL.map((DataManagementMigration, index) => (
            <p key={index}>{DataManagementMigration}</p>
          ))}
          <h5>Cloud Data Migration:</h5>
          {data.description.datamigration.CDM.map((DataManagementMigration, index) => (
            <p key={index}>{DataManagementMigration}</p>
          ))}
          <h5>Legacy System Migration:</h5>
          {data.description.datamigration.LSM.map((DataManagementMigration, index) => (
            <p key={index}>{DataManagementMigration}</p>
          ))}
          <p>{data.description.content2}</p>





          
        </div>
      </div>
    </div>
  );
}
export default DataManagementMigration;


