import React, { useState } from "react";
import "../CaseStudies.css";
import { NavLink } from "react-router-dom";

import CSLogo1 from "../images/cs-clientlogo-1.png";
import CSLogo2 from "../images/cs-clientlogo-2.png";
import CSLogo3 from "../images/cs-clientlogo-3.png";

function CaseStudies() {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="fade-in">
      <div className="case-studies-wrap">
        <div className="case-studies-1">
          <ul>
            <li>
              <NavLink
                className={activeTab === 1 ? "selected" : ""}
                onClick={() => handleTabClick(1)}
              >
                <img src={CSLogo1} alt={CSLogo1} />
              </NavLink>
            </li>
            <li>
              <NavLink
                className={activeTab === 2 ? "selected" : ""}
                onClick={() => handleTabClick(2)}
              >
                <img src={CSLogo2} alt={CSLogo1} />
              </NavLink>
            </li>
            <li>
              <NavLink
                className={activeTab === 3 ? "selected" : ""}
                onClick={() => handleTabClick(3)}
              >
                <img src={CSLogo3} alt={CSLogo1} />
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="case-studies-2">
          <div className={activeTab === 1 ? "show-content" : "content"}>
            {/* Content for Tab 1 */}
            <h3>Implementing Secure Online Voting</h3>
            <h4>Background:</h4>
            <p>
              ACME is a FMCG company that has been growing rapidly since its
              inception. The company has a board of directors who have been
              involved in decision-making processes, but until now, there has
              been no clear voting process in place for the shareholders.{" "}
            </p>
            <p>
              Another use case was employee unions which frequently runs their
              election for each of their chapters pan USA and bylaw amendments
              using paper. These organizations were looking for a secure
              solution as there was a lot PII that collected by them.{" "}
            </p>
            <h4>Challenge:</h4>
            <p>
              Clients were aware of the need to provide stakeholders with a
              voting process. However, they are concerned as to how they manage
              expectations and ensure that the process is transparent and
              secure. Disputes and legal battles are a common occurrence and
              want to avoid these situations.
            </p>
            <NavLink
              to="../CaseStudyOne"
              onClick={scrollToTop}
              className="submit-1 m-t-2"
            >
              Read more
            </NavLink>
          </div>

          <div className={activeTab === 2 ? "show-content" : "content"}>
            {/* Content for Tab 2 */}
            <h3>Global OEM Telco Company</h3>
            <h4>Problem Statement</h4>
            <ul>
              <li>
                Visibility to Network issues across CORE and Periphery Network.
              </li>
              <li>
                Monitoring 30 + Locations with Data Centers and Testing Labs.
              </li>
              <li>Frequent Outages Impacting Production.</li>
              <li>The outage Handling process was missing.</li>
              <li>Application Outages</li>
              <li>Skill Gaps</li>
            </ul>
            <NavLink
              to="../CaseStudyTwo"
              onClick={scrollToTop}
              className="submit-1 m-t-2"
            >
              Read more
            </NavLink>
          </div>

          <div className={activeTab === 3 ? "show-content" : "content"}>
            {/* Content for Tab 3 */}
            <h3>Thermo Fisher</h3>

            <h4>Application</h4>
            <p>RMP - Twilio - International Messaging</p>
            <h4>Problem of our customer we were addressing</h4>
            <p>
              To enguage and retain the patients Sending messages, Making calls
              internationally on timely basis and based on user specific
              language
            </p>
            <h4>Our solution</h4>
            <p>
              International messaging is a micro service application developed
              to send scheduled and instant messages to the users all over the
              world. The messaging application sends messages based on the
              country and language. The message will be delivered according to
              the local time and the language proffered by the user. This is
              developed as a service as to be used across all the applications
              in Acurian.
            </p>
            <NavLink
              to="../CaseStudyThree"
              onClick={scrollToTop}
              className="submit-1 m-t-2"
            >
              Read more
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaseStudies;
