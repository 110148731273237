import React from 'react'
import './preloader.css'

function Preloader() {
  return (
    <div className='Loadmain'>
      <div class="loader"></div>
    <div class="loader"></div>
    <div class="loader"></div>
    <div class="loader"></div>
    <div class="loader"></div>
    </div>
  )
}

export default Preloader

