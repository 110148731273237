import React from 'react';
import emailjs from 'emailjs-com';
import InnerShapeOne from '../images/inner-banner-shape.png';
//import ContactPatch1 from '../images/contactus-patch-1.png';
import ContactPatch2 from '../images/contactus-patch-2.png';
//import MapImg1 from '../images/map.png';

function ContactUs() {
  
  function sendEmail (e){
    e.preventDefault();
    emailjs.sendForm('service_eas5kwh', 'service_eas5kwh', e.target, '')
  }
  return (
    <div>
    <div className='inner-banner-wrap m-t-1 ContactUs-banner'>
      <div className='wrapper'>
        <h1 className='banner-text-center'><b>Contact</b> Us</h1>
      </div>
       
    </div>
<div className='inner-bg-2-main'>

<div className='inner-bg-3'>
      {/* <div className='wrapper'>
        <div className='map-sec'>
          <h2>Our Team is having Worldwide Presence</h2>
        <img src={MapImg1} alt={MapImg1} />
        </div>
        </div> 

        <div class="strip-100"><img src={ContactPatch1} alt='ContactPatch1' /></div>*/}

        <div className='our-office-bg'>

          <div className='wrapper'>
            <h2>Our Offices</h2>
            <div className='our-office-address'>
              <div className='our-office-address-1'>
                <div className='our-office-address-1-1'>
                  <h4>US Office</h4>
                  <div className='contact-icon location-icon'>3312 Rosedale St, Ste 202C Gig Harbor, WA 98335-1804</div>
                  
                  <div className='contact-icon email-icon'>info@nfinity-global.com</div>
                </div>
               
              </div>
              <div className='our-office-address-1'>
                <div className='our-office-address-1-1'>
                <h4>UK Office</h4>
                  <div className='contact-icon location-icon'>17 Firdene, Surbiton, Surrey, United Kingdom, KT5 9QQ</div>
                  
                  <div className='contact-icon email-icon'>uk@nfinity-global.com</div>
                </div>
                
              </div>


              <div className='our-office-address-1'>
                <div className='our-office-address-1-1'>
                  <h4>INDIA Office</h4>
                  <div className='contact-icon location-icon'>
                    484/A. Nfinity Solutions. 3rd&nbsp;Floor. KVK&nbsp;Chambers, Opp. IDBI&nbsp;Bank, Jubilee&nbsp;Hills, Road&nbsp;No.&nbsp;36, Hyderabad, Telangana - 500033
                    </div>
                    <div className='contact-icon email-icon'>info@nfinity-global.com</div>
                </div>
               
              </div>
              <div className='our-office-address-1'></div>
              {/*<div className='our-office-address-1'>
                <div className='our-office-address-1-1'>
                  <h4>Philippines Office</h4>
                  <div className='contact-icon location-icon'>
                    O&nbsp;S&nbsp;I&nbsp;Consulting&nbsp;Inc.,
                    20th&nbsp;Floor&nbsp;Regus,
                    Zuellig&nbsp;Building&nbsp;Makati
                    Ave.coor&nbsp;Paseo&nbsp;de&nbsp;Roxas,
                    Makati&nbsp;City
                    </div>
                  <div className='contact-icon phone-icon'>+63-2-545-8093</div>
                </div>
                <div className='our-office-address-1-2'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15446.891549807016!2d121.0266625!3d14.557833!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c906bb69fced%3A0x5fb7645c4ba77f42!2sRegus%20-%20Manila%2C%20Zuellig%20Building%20Makati!5e0!3m2!1sen!2sin!4v1694762070009!5m2!1sen!2sin" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='Gig Harbor' id='Gig Harbor'></iframe>
                </div>
              </div>
              */}

              {/*
              <div className='form-sec-main'>
          <h2 className='w-100'>If you are interested in our services and would like to get in touch with us, feel free to fill below form and our team will contact you shortly.</h2>
           <form onSubmit={sendEmail}>
            <div className='form-sec-1'><input type='text' className='text-1' name="name" placeholder='Name' /></div>
            <div className='form-sec-1'><input type='text' className='text-1' name="email" placeholder='Email' /></div>
            <div className='form-sec-1'><input type='text' className='text-1' name="phone" placeholder='Phone No.' /></div>
            <div className='form-sec-1'><input type='text' className='text-1' name="subject" placeholder='Subject' /></div>
            <div className='form-sec-1 w-100'><textarea className='textarea-1' name="message" id="" cols="30" rows="10"  placeholder='Message'></textarea></div>
            <div className='form-sec-1 w-100 text-center'>
              <input type='submit' value='Submit' className='submit-1'></input>
            </div>
            </form> 
        </div>
              */}



            </div>

          </div>
        </div>
       
        <div className='form-sec-main'>
          <h2 className='w-100'>If you are interested in our services and would like to get in touch with us, feel free to fill below form and our team will contact you shortly.</h2>
           <form onSubmit={sendEmail}>
            <div className='form-sec-1'><input type='text' className='text-1' name="name" placeholder='Name' required /></div>
            <div className='form-sec-1'><input type='text' className='text-1' name="email" placeholder='Email' required /></div>
            <div className='form-sec-1'><input type='text' className='text-1' name="phone" placeholder='Phone No.' required /></div>
            <div className='form-sec-1'><input type='text' className='text-1' name="subject" placeholder='Subject' required /></div>
            <div className='form-sec-1 w-100'>
             <select className='text-1' required>
              <option>What you're looking for?</option>
              <option>Information on Services</option>
              <option>Request a Quote</option>
            </select>
            </div>
            <div className='form-sec-1 w-100'><textarea className='textarea-1' name="message" id="" cols="30" rows="10"  placeholder='Message'></textarea></div>
            <div className='form-sec-1 w-100 text-center'>
              <input type='submit' value='Submit' className='submit-1'></input>
            </div>
            </form> 
        </div>

    </div>
</div>

</div>
  )
}

export default ContactUs
