import React from 'react'
import InnerShapeOne from '../images/inner-banner-shape.png';
import CSLogo1img1 from '../images/casestudies-img-1.png';

function CaseStudyOne() {
  return (
    <div className='fade-in'>
    <div className='inner-banner-wrap m-t-1'>
      <div className='wrapper'>
        <h1 className='banner-text-center'><b>Case Studies</b>  Inner</h1>
      </div>
       
    </div>
<div className='inner-bg-2-main'>
<div className='inner-bg-2'>
      <div className='wrapper-2'>
        <h2>Case Study: Implementing Secure Online Voting</h2>
        <img src={CSLogo1img1} alt={CSLogo1img1} />

<h4>Background:</h4>
<p>ACME is a FMCG company that has been growing rapidly since its inception. The company has a board of directors who have been involved in decision-making processes, but until now, there has been no clear voting process in place for the shareholders. </p>
<p>Another use case was employee unions which frequently runs their election for each of their chapters pan USA and bylaw amendments using paper. These organizations were looking for a secure solution as there was a lot PII that collected by them. </p>
<h4>Challenge:</h4>
<p>Clients were  aware of the need to provide stakeholders with a voting process. However, they are concerned as to  how they manage expectations and ensure that the process is transparent and secure. Disputes and legal battles are a common occurrence and want to avoid these situations.</p>
<h4>Solution:</h4>
<p>A voting platform was designed to facilitate secure and efficient online voting. This platform was user-friendly and intuitive, and it allows members/voters to view candidate profiles and nomination options. The voting process is designed to allow voters to cast their votes easily via web, mobile or IVRS while also providing transparency and security.</p>
<p>Solution was built with .net using micro services to support web and IVRS solution. Application servers were deployed on AWS with autoscale. This allowed the application servers to scale out ensuring Higher Availability. MSSQL RDS was used to ensure database could scale up to handle higher traffic.
  To address security concerns,  eBallot engaged a third party auditing firm called Trustedsite. This vendor audits the infrastructure and application security periodically and issues a certificate only if application/infrastructure passes the audit successfully. </p>
<h4>Results:</h4>
<ul>
  <li>Easily setup ballot and notify eligible voters, eliminating use of paper and postal services to notify voters/members and arrest delays.</li>
  <li>Manual errors eliminated.</li>
  <li>Data is securely stored</li>
  <li>Results are made available to all at the appointed hour</li>
  <li>Reports and audit trails help clients answer all queries/questions of voters/members.</li>
</ul>
        
      </div>
    </div>
</div>
</div>
  )
}
export default CaseStudyOne
