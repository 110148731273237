const clients = [
    {
        albumId: 3,
        id: 1,
        title: "",
        url: "./images/clients-thumb-1.png"
      },
      {
        albumId: 3,
        id: 2,
        title: "",
        url: "../images/clients-thumb-2.png"
      },
      {
        albumId: 3,
        id: 3,
        title: "",
        url: "../images/clients-thumb-3.png"
      },

      {
        albumId: 3,
        id: 4,
        title: "",
        url: "./images/clients-thumb-4.png"
      },
      {
        albumId: 3,
        id: 5,
        title: "",
        url: "../images/clients-thumb-5.png"
      },
      {
        albumId: 3,
        id: 6,
        title: "",
        url: "../images/clients-thumb-6.png"
      },

      {
        albumId: 3,
        id: 7,
        title: "",
        url: "./images/clients-thumb-7.png"
      },
      {
        albumId: 3,
        id: 8,
        title: "",
        url: "../images/clients-thumb-8.png"
      },
      {
        albumId: 3,
        id: 9,
        title: "",
        url: "../images/clients-thumb-9.png"
      }
]
export default clients; 