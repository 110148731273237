import React from 'react'
import InnerShapeOne from '../images/inner-banner-shape.png';
import ManagedServicesImg from '../images/EngineeringandITServices.png';


function BuildRolloutUpgradeOperateMaintain() {
  return (
    <div className='fade-in'>
    <div className='inner-banner-wrap m-t-1'>
      <div className='wrapper'>
        <h1 className='banner-text-center'><b>Build, Roll-out, Upgrade,  </b> Operate, Maintain and Transfer network </h1>
      </div>
       
    </div>

    <div className='inner-page-bg-1'>
      <div className='wrapper'>
        <div className='two-col-1-main'>
          <div className='two-col-1-1'>
            
            <p>content will be posted soon..</p>

          </div>
          <div className='two-col-1-2'><img src={ManagedServicesImg} alt='ManagedServicesImg' /></div>
        </div>

      </div>

    </div>
   
    
 
</div>
  )
}

export default BuildRolloutUpgradeOperateMaintain
