import React , {useState , useEffect} from "react";
import InnerShapeOne from "../images/inner-banner-shape.png";
import CareerImg from "../images/business.png";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

// import jobData from "./SepareteJobData";


function Careers() {
 
  const [careerData , setCareerData] = useState([]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  // http://nfinity.psmprojects.net/career.php

  useEffect(() => {
    const fetchCarrerData = async() => {
      try{
        const response = await fetch('http://nfinity.psmprojects.net/career.php');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        if (jsonData){
          const fetchedData = jsonData.map(eachItem => ({
            id : eachItem.id, 
            job_title : eachItem.job_title,
            company_name : eachItem.company_name,
            location : eachItem.location,
            description : eachItem.description,
          }))
          setCareerData(fetchedData)
        }

      }
      catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchCarrerData();
  },[])


  return (
    <div>
      <div className="inner-banner-wrap m-t-1 Careers-banner">
        <div className="wrapper">
          <h1 className="banner-text-center">
            <b>Careers </b>
          </h1>
        </div>
        
      </div>

      <div className="inner-page-bg-1">
        <div className="wrapper">
          <div>
            
            {/* <ul>
              {jobData.map((job) => (
                <li key={job.id}>
                  <div className="current-openings-box">
                    <div className="cob-1">
                      <h2>
                        <img src={CareerImg} alt="InnerShapeOne" /> {job.title}
                      </h2>
                      <p><b>Company :</b>{job.company}</p>
                      <p><b>Location :</b> ({job.location})</p>
                      <p><b>Description :</b> {job.description}</p>
                      
                    </div>
                    <div className="cob-2">
                      <div className="cob-2-1">
                        <Link to={`/career-details/${job.id}`} onClick={scrollToTop}>
                          <strong>Read more</strong>
                        </Link>
                      </div>
                      <div className="cob-2-2">
                        {/*<button class="button-4">Apply Now</button>*/}
                      {/* </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul> */} 

            <ul>
              {
                careerData.map((job) => (
                  <li key={job.id}>
                    <div className="current-openings-box">
                    <div className="cob-1">
                      <h2>
                        <img src={CareerImg} alt="InnerShapeOne" /> {job.job_title}
                      </h2>
                      <p><b>Company :</b>{job.company_name}</p>
                      <p><b>Location :</b> ({job.location})</p>
                      <p><b>Description :</b> {job.description}</p>
                      <div className="cob-2">
                      <div className="cob-2-1">
                        <Link to={`/career-details/${job.id}`} onClick={scrollToTop}>
                          <strong>Read more</strong>
                        </Link>
                      </div>
                      </div>
                    </div>
                    </div>
                  </li>
                ))
              }
            </ul>
          </div>

          <hr></hr>


          {/* <div className="two-col-1-main">
            <div className="two-col-1-1">
              <div className="current-openings-box">
                <div className="cob-1">
                  <h2>
                    <img src={CareerImg} alt="InnerShapeOne" /> AV/VC Engineer
                  </h2>
                  <p>
                    {" "}
                    We are looking for AV/VC Engineer resource having 5+ Yrs of
                    strong experience audio visual equipment in a corporate
                    environment and an understanding of audio/video signal flow
                    in areas. Please refer the JD for more details. Location:
                    Gurgaon Work Model: Hybrid (On-site and Remote)
                  </p>
                </div>
                <div className="cob-2">
                  <div className="cob-2-1">
                    <NavLink to="../CareerPostOne" onClick={scrollToTop}>
                      Read more{" "}
                    </NavLink>
                  </div>
                  <div className="cob-2-2">
                  
                  </div>
                </div>
              </div>

              <div className="current-openings-box">
                <div className="cob-1">
                  <h2>
                    <img src={CareerImg} alt="InnerShapeOne" /> Regional IT
                    Admin
                  </h2>
                  <p>
                    We are looking for regional IT Admin resource having 3+ Yrs
                    of strong experience in Microsoft Office tools and basic
                    knowledge on IT support. Please refer the JD for more
                    details. <br />
                    Location: Gurgaon Work Model: Hybrid (On-site and Remote)
                  </p>
                </div>
                <div className="cob-2">
                  <div className="cob-2-1">
                    <NavLink to="../CareerPostTwo" onClick={scrollToTop}>
                      Read more{" "}
                    </NavLink>
                  </div>
                  <div className="cob-2-2">
                   
                  </div>
                </div>
              </div>

              <div className="current-openings-box">
                <div className="cob-1">
                  <h2>
                    <img src={CareerImg} alt="InnerShapeOne" /> Full Stack
                    Developer{" "}
                  </h2>
                  <p>
                    <br />
                    Skills Required: Front end development with React.js, back
                    end Node.js or Java <br />
                    Location: Hyderabad Work Model: Hybrid (On-site and Remote)
                  </p>
                </div>
                <div className="cob-2">
                  <div className="cob-2-1">
                    <NavLink to="../CareerPostThree" onClick={scrollToTop}>
                      Read more{" "}
                    </NavLink>
                  </div>
                  <div className="cob-2-2">
                    
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Careers;
