import React from 'react'
import InnerShapeOne from '../images/inner-banner-shape.png';
import CSLogo1img1 from '../images/casestudies-img-1.png';

function CaseStudiesInner() {
  return (
    <div className='fade-in'>
    <div className='inner-banner-wrap m-t-1'>
      <div className='wrapper'>
        <h1 className='banner-text-center'><b>Case Studies</b>  Inner</h1>
      </div>
       
    </div>
<div className='inner-bg-2-main'>
<div className='inner-bg-2'>
      <div className='wrapper-2'>
        <h2>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h2>
        <img src={CSLogo1img1} alt={CSLogo1img1} />
        <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h3>
        <p>Nfinity Global offers a comprehensive range of Managed Services designed to optimize and support your organization's IT infrastructure. With a focus on efficiency and reliability, our Managed Services cover key areas such as end user support, 24x7 network operation, application monitoring, SOC monitoring, AV and network remote monitoring and management, as well as hardware asset management.</p>
        <p>Our team of skilled professionals is dedicated to ensuring smooth IT operations, delivering prompt assistance and proactive monitoring to keep your systems running at their best. We handle critical tasks such as resolving user issues, monitoring network performance, and managing applications and security to minimize downtime and enhance productivity.</p>
        <p>By entrusting your IT management to Nfinity Global, you can confidently focus on your core objectives, knowing that your IT infrastructure is in capable hands. Our Managed Services provide you with the peace of mind to drive your organization forward, while we handle the complexities of IT operations, ensuring optimal performance, security, and efficiency.</p>

      </div>
    </div>
</div>
</div>
  )
}
export default CaseStudiesInner
