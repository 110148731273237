import React from 'react'
//import FooterImg from '../images/footer-shape.png';

import Logo from '../images/Logo.png';
import { NavLink } from 'react-router-dom'
import Logowhite from "../images/logo-white.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';

function Footer() {
 
  const scrollToTop = () => {
    window.scrollTo(0, 0)
}

  return (
    <div>
      {/* <div className='strip-100'>
        <img src={FooterImg} alt='FooterImg' />
      </div> */}
      <div className='footer-bg'>
        <div className='wrapper'>
        <div className='footer-wrap'>
          <div className='footer-item'><img src={Logowhite} alt='' /></div>
          <div className='footer-item'>
            <h4>CONTACT US</h4> 
            <p><FontAwesomeIcon icon={faPhone} /> +1 (833) 646-3988</p> 
            <p><FontAwesomeIcon icon={faEnvelope} /> info@nfinity-global.com</p> 
          </div>
          <div className='footer-item'>
            <h4>QUICK LINKS</h4> 
            <ul>
              <li onClick={scrollToTop}><NavLink to="../solutions_audio_and_visual" >Solutions</NavLink></li>
              <li onClick={scrollToTop}><NavLink to="../our_services" >Services</NavLink></li>
              <li onClick={scrollToTop}><NavLink to="../client_experience">Client Experience</NavLink></li>
              </ul> 
          </div>
          <div className='footer-item'>
            <h4>COMPANY</h4> 
            <ul>
            <li onClick={scrollToTop}><NavLink to="../company_news" >Learn</NavLink></li>
              <li onClick={scrollToTop}><NavLink to="../about_us" >About Us</NavLink></li>
              <li onClick={scrollToTop}><NavLink to="../contact_us">Contact Us</NavLink></li>
              </ul> 
          </div>
          
          
          <div className='footer-item'>
            <h4>FOLLOW US</h4>
            <FontAwesomeIcon icon={faFacebook} className='social-media-1' />
            <FontAwesomeIcon icon={faTwitter} className='social-media-1' />
            <FontAwesomeIcon icon={faLinkedin} className='social-media-1' />
          </div>

        </div>
        <div className='copy-right'>
          <div className='copy-1'>Copyright 2024. Nfinity Global. All Rights Reserved. | Concept & Design by eparivartan</div>
          <div className='copy-2'>
          <NavLink to="../contact_us">Privacy Policy </NavLink>
          <NavLink to="../contact_us">Terms and Conditions</NavLink>
          </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer


