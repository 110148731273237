import React from "react";
import InnerShapeOne from "../images/inner-banner-shape.png";
//import CSLogo1img1 from "../images/casestudies-img-1.png";

function CareerPostThree() {
  return (
    <div className=" fade-in">
      <div className="inner-banner-wrap m-t-1">
        <div className="wrapper">
          <h1 className="banner-text-center">
            <b>Career</b> 
          </h1>
        </div>
       
      </div>
      <div className="inner-bg-2-main">
        <div className="inner-bg-2">
          <div className="wrapper-2">
            <h2>
            Full Stack Developer:
            </h2>
            
            <h3>
            Overview:
            </h3>
            
            <p>Must have experience in Full Stack Web Development using React, Node.</p>
        <p>Must have strong experience on React, JavaScript Frameworks, (Angular nice to have), HTML, CSS, JavaScript</p>
        <p>Must have strong experience Node.js, REST APIs</p>
        <p>Experience in working with Agile team and JIRA, Confluence or other knowledge management system.</p>
        <p>Must have experience of working in SCRUM/AGILE</p>
        <p>Create RESTful and/or Open Graph APIs</p>
        <p>Experience on HTML, CSS, JavaScript</p>
           
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default CareerPostThree;
