const whywebestdata = [
    {
        albumId: 2,
        id: 1,
        title: "Nfinity delivers services of exceptional customer and employee experiences.",
        url: "./images/wwwab-icon-1.png"
        
      },
      {
        albumId: 2,
        id: 2,
        title: "We specialize in “Time and Materials” or SOW model that best suits your business needs. ",
        url: "../images/wwwab-icon-2.png"
        
      },
      {
        albumId: 2,
        id: 3,
        title: "Nfinity brings in vast experience and technical expertise in the range of products and services we offer from our global operations.",
        url: "../images/wwwab-icon-3.png"
       
      },
      {
        albumId: 2,
        id: 4,
        title: "Nfinity delivers services of exceptional customer and employee experiences.",
        url: "./images/wwwab-icon-1.png"
        
      },
      {
        albumId: 2,
        id: 5,
        title: "We specialize in “Time and Materials” or SOW model that best suits your business needs. ",
        url: "../images/wwwab-icon-2.png"
        
      },
      {
        albumId: 2,
        id: 6,
        title: "Nfinity brings in vast experience and technical expertise in the range of products and services we offer from our global operations.",
        url: "../images/wwwab-icon-3.png"
       
      },
      
]
export default whywebestdata; 