import React from "react";
import Revolutionize from "./Revolutionize"
import { NavLink } from "react-router-dom";
import servicedata from "./servicedata.js";

function ApplicationDevelopment() {
  const data = servicedata.ApplicationDevelopment;
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="fade-in">
    <div className="inner-banner-wrap m-t-1">
      <div className="wrapper">
        <h1 className="banner-text-center">
          {data.title}
        </h1>
        <p>{data.shorttitle}</p>
      </div>
    </div>
    <div className="inner-services-r-bg">
      <div className="wrapper">
        {data.description.map((description, index) => (
            <p key={index}>{description}</p>
          ))}
      </div>
    </div>
  
  
  </div>
  );
}
export default ApplicationDevelopment;

