import React, { useState } from "react";
import emailjs from "emailjs-com";
import InnerShapeOne from "../images/inner-banner-shape.png";
import ContactPatch1 from "../images/contactus-patch-1.png";
import ContactPatch2 from "../images/contactus-patch-2.png";
import MapImg1 from "../images/map.png";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [formStatus, setFormStatus] = useState({
    submitted: false,
    error: false,
    success: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    return (
      formData.name !== "" &&
      formData.email !== "" &&
      formData.phone !== "" &&
      formData.subject !== "" &&
      formData.message !== ""
    );
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (validateForm()) {
      const templateParams = {
        from_name: formData.name,
        from_email: formData.email,
        phone_number: formData.phone,
        subject: formData.subject,
        message: formData.message,
      };

      emailjs
        .send(
          "service_eas5kwh",
          "template_vvxa31t",
          templateParams,
          "UhUU0aDn6krbUwQPX"
        )
        .then((response) => {
          console.log(
            "Email successfully sent!",
            response.status,
            response.text
          );
          setFormStatus({
            submitted: true,
            error: false,
            success: true,
          });
          setFormData({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
          });
        })
        .catch((error) => {
          console.error("Error sending email:", error);
          setFormStatus({
            submitted: true,
            error: true,
            success: false,
          });
        });
    } else {
      setFormStatus({
        submitted: true,
        error: true,
        success: false,
      });
    }
  };

  return (
    <div>
      <div className="inner-banner-wrap m-t-1">
        <div className="wrapper">
          <h1 className="banner-text-center">
            <b>Contact</b> Us
          </h1>
        </div>
      
      </div>

      <div className="inner-bg-2-main">
        <div className="inner-bg-3">
          <div className="wrapper">
            <div className="map-sec">
              <h2>Our Team is having Worldwide Presence</h2>
              <img src={MapImg1} alt={MapImg1} />
            </div>
          </div>

          <div class="strip-100">
            <img src={ContactPatch1} alt="ContactPatch1" />
          </div>

          <div className="our-office-bg">
            <div className="wrapper">
              <h2>Our Offices</h2>
              <div className="our-office-address">
                <div className="our-office-address-1">
                  <div className="our-office-address-1-1">
                    <h4>US Office</h4>
                    <div className="contact-icon location-icon">
                      3312 Rosedale St, Ste 202C Gig Harbor, WA 98335-1804
                    </div>

                    <div className="contact-icon email-icon">
                      info@nfinity-global.com
                    </div>
                  </div>
                  <div className="our-office-address-1-2">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2704.2093259597546!2d-122.58347490000001!3d47.3297831!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x549052c39af40107%3A0xa7ac5fdf0aa8818c!2s3312%20Rosedale%20St%20NW%20%23202c%2C%20Gig%20Harbor%2C%20WA%2098335%2C%20USA!5e0!3m2!1sen!2sin!4v1694676146876!5m2!1sen!2sin"
                      width="100%"
                      height="450"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                      title="Gig Harbor"
                      id="Gig Harbor"
                    ></iframe>
                  </div>
                </div>
                <div className="our-office-address-1">
                  <div className="our-office-address-1-1">
                    <h4>UK Office</h4>
                    <div className="contact-icon location-icon">
                      17 Firdene, Surbiton, Surrey, United Kingdom, KT5 9QQ
                    </div>

                    <div className="contact-icon email-icon">
                      uk@nfinity-global.com
                    </div>
                  </div>
                  <div className="our-office-address-1-2">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2490.1817654955375!2d-0.27247679999999996!3d51.3813373!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760984573a01e1%3A0x51de9a40b0f04d0d!2s17%20Firdene%2C%20Surbiton%20KT5%209QQ%2C%20UK!5e0!3m2!1sen!2sin!4v1694761656500!5m2!1sen!2sin"
                      width="100%"
                      height="450"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                      title="Gig Harbor"
                      id="Gig Harbor"
                    ></iframe>
                  </div>
                </div>

                <div className="our-office-address-1">
                  <div className="our-office-address-1-1">
                    <h4>INDIA Office</h4>
                    <div className="contact-icon location-icon">
                      484/A. Nfinity Solutions. 3rd&nbsp;Floor.
                      KVK&nbsp;Chambers, Opp. IDBI&nbsp;Bank,
                      Jubilee&nbsp;Hills, Road&nbsp;No.&nbsp;36, Hyderabad,
                      Telangana - 500033
                    </div>
                    <div className="contact-icon email-icon">
                      info@nfinity-global.com
                    </div>
                  </div>
                  <div className="our-office-address-1-2">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15226.338466771414!2d78.407153!3d17.431711!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91362977f87f%3A0xf0cbff8af788eca0!2sNFINITY%20SOLUTIONS%20PVT.LTD!5e0!3m2!1sen!2sin!4v1694762017730!5m2!1sen!2sin"
                      width="100%"
                      height="450"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                      title="Gig Harbor"
                      id="Gig Harbor"
                    ></iframe>
                  </div>
                </div>
                <div className="our-office-address-1"></div>
                {/*<div className='our-office-address-1'>
                <div className='our-office-address-1-1'>
                  <h4>Philippines Office</h4>
                  <div className='contact-icon location-icon'>
                    O&nbsp;S&nbsp;I&nbsp;Consulting&nbsp;Inc.,
                    20th&nbsp;Floor&nbsp;Regus,
                    Zuellig&nbsp;Building&nbsp;Makati
                    Ave.coor&nbsp;Paseo&nbsp;de&nbsp;Roxas,
                    Makati&nbsp;City
                    </div>
                  <div className='contact-icon phone-icon'>+63-2-545-8093</div>
                </div>
                <div className='our-office-address-1-2'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15446.891549807016!2d121.0266625!3d14.557833!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c906bb69fced%3A0x5fb7645c4ba77f42!2sRegus%20-%20Manila%2C%20Zuellig%20Building%20Makati!5e0!3m2!1sen!2sin!4v1694762070009!5m2!1sen!2sin" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='Gig Harbor' id='Gig Harbor'></iframe>
                </div>
              </div>
              */}
              </div>
            </div>
          </div>
          <div class="strip-100">
            <img src={ContactPatch2} alt="ContactPatch2" />
          </div>
          <div className="form-sec-main">
            {formStatus.submitted &&
              (formStatus.success ? (
                <p style={{ color: "green" }}>Email sent successfully!</p>
              ) : (
                <p style={{ color: "red" }}>
                  Error sending email. Please try again.
                </p>
              ))}
            <form onSubmit={sendEmail}>
              <div className="form-sec-1">
                <label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Enter your name"
                    className="text-1"
                    required
                  />
                </label>
              </div>
              <div className="form-sec-1">
                <label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Enter your email"
                    className="text-1"
                    required
                  />
                </label>
              </div>
              <div className="form-sec-1">
                <label>
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    placeholder="Enter your phone number"
                    className="text-1"
                    required
                  />
                </label>
              </div>
              <div className="form-sec-1">
                <label>
                  <input
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    placeholder="Enter the subject"
                    className="text-1"
                    required
                  />
                </label>
              </div>
              <div className="form-sec-1 w-100">
                <label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    placeholder="Enter your message"
                    className="textarea-1"
                    required
                  />
                </label>
              </div>
              <div className="form-sec-1 w-100">
                <button type="submit">Send</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
