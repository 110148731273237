import React from "react";
import Revolutionize from "./Revolutionize";
import { NavLink } from "react-router-dom";
import servicedata from "./servicedata.js";

function EngineeringandITServices() {
  const EngineeringandITServices = servicedata.EngineeringandITServices;

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="fade-in">
      <div className="inner-banner-wrap m-t-1 EngineeringandITServices-banner">
        <div className="wrapper">
          <h1 className="banner-text-center">
            {EngineeringandITServices.title}
          </h1>
          <p>{EngineeringandITServices.shorttitle}</p>
        </div>
      </div>
      <div className="services-r-bg">
        <div className="wrapper">
          {EngineeringandITServices.description.map((description, index) => (
            <p key={index}>{description}</p>
          ))}
        </div>
      </div>
      <div className="services-r-bg-1 EngineeringandITServices-img">
        <div className="wrapper-4">
          <div className="services-r-list-wrap">
            <ul>
              {EngineeringandITServices.sublists.map((item, index) => (
                <li key={index}>
                  <NavLink to={`../${item.link}`} onClick={scrollToTop}>
                  {item.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <Revolutionize />
    </div>
  );
}

export default EngineeringandITServices;
