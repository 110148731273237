const servicedata = {
EngineeringandITServices : {
id : "EngineeringandITServices",
url:"engineering_and_IT_services",
title : "Engineering and IT Services",
shorttitle : "Accelerate Your Digital Evolution - Advance with our dynamic IT engineering services, fortifying your infrastructure from DevOps to cybersecurity and cloud solutions.",
description : [
"Throughout our journey,  Nfinity has consistently delivered a versatile array of IT Engineering Services designed to propel our customers' digital evolution. From DevOps and Cloud Infrastructure Services, ensuring streamlined development, efficient operations, and scalable cloud solutions, to robust Cyber Security Services fortifying digital defenses.",
"Our dedicated Application Support Services guarantee the seamless operation of applications, while our Data Management & Migration Services ensure secure and efficient handling of data across platforms.",
"At Nfinity, we've remained committed to offering a holistic approach, collaborating with our clients to build resilient and future-ready IT infrastructures, positioning their organizations for success in the dynamic digital landscape."
],
sublists : [
{ name: "DevOps", link: "DevOps" },
{ name: "Application Support Service", link: "application_development" },
{ name: "Cloud Infrastructure Services", link: "cloud_infrastructure" },
{ name: "Data Management & Migration Services", link: "data_management_migration" },
{ name: "Cyber Security Services", link: "cyber_security" },
]


},

ManagedServices: {
id : "ManagedServices",
url:"managed_services",
title : "Managed Services",
shorttitle : "Seamless IT Operations, Enduring Transformation - Elevate your business with our expertly managed IT services, ensuring productivity and paving the way for lasting growth.",
description : [
"With an esteemed legacy,  Nfinity Global emerges as a pioneer, delivering impeccably streamlined Managed Services to fortify clients' IT infrastructure. Our unwavering commitment revolves around ensuring operational efficiency and reliability, covering vital services like end-user support, 24/7 network management, and vigilant application and SOC monitoring.",
"Complemented by robust hardware asset management, our seasoned team dedicates itself to nurturing seamless IT operations, providing prompt support, and consistently maintaining peak system performance. From addressing user issues to monitoring network health and fortifying application security, our diligence minimizes downtime and maximizes productivity.",
"Select Nfinity Global as your enduring IT management partner, and confidently focus on your business goals. Our proven track record ensures heightened performance and security. Contact us today to embark on a transformative journey for your IT infrastructure."
],
sublists : [
{ name: "IT Managed Service Desk", link: "it_managed_service_desk" },
{ name: "On-site & Remote Level 2 Support",  link: "on_site_remott_level_2_support" },
{ name: "24x7 Network Operation", link: "network_operation_247" },
{ name:  "24x7 Application Monitoring", link: "application_monitoring_247" },
{ name: "NOC (NetworkOperationsCenter)", link: "network_operations_center" },
{ name: "24x7 AV Managed Services", link: "avmanaged_services_247" },
{ name: "Managed Network Infrastructure", link: "managed_network_infrastructure" },
{ name: "IT Asset Deployment Services", link: "itas_set_seployment_services" },
]




},

AudioAndVisual : {
id : "AudioAndVisual",
url:"audio_and_visual",
title : "Audio And Visual",
shorttitle : "Enrich Your Workspaces - Transform your events and productivity with our top-tier audiovisual services, proactive upkeep, and innovative digital solutions.",
description : [

"Nfinity Global, an  industry leader, delivers unparalleled Audio and Visual Services, specializing in seamless technical support for diverse events. With a proactive approach, we implement  preventative maintenance to minimize disruptions and extend system longevity.",
"Nfinity's dedication to staying at the forefront of technological advancements is reflected in a range of digital  media offerings. Choose Nfinity Global for a proven track record in delivering cutting-edge solutions, elevating organizational communications, and ensuring technological innovation.",
"Our responsive services efficiently address urgent technical needs, ensuring minimal downtime and maximizing productivity.  Renowned for a commitment to excellence, Nfinity Global crafts bespoke audio, visual, and collaboration solutions, enhancing workplace productivity.",
],
sublists : [
{ name:  "Refresh Upgrade", link: "refresh_upgrade" },
{ name:  "White-glove Meeting & EventSupport", link: "whiteglove_meeting_event_support" },
{ name: "Data Cabling", link: "data_cabling" },
{ name: "Preventative Maintenance", link: "preventative_maintenance" },
{ name: "Dispatched Services", link: "dispatched_services_two" },
]

},

NetworkInfrastructure : {
id : "NetworkInfrastructure",
url:"managed_network_infrastructure",
title : "Network Infrastructure",
shorttitle : "Revolutionize Your Network Infrastructure - Experience unparalleled connectivity and global communication with our thorough audits and seamless network maintenance.",
description : [
"In today's interconnected world, Nfinity specializes in delivering cutting-edge IT infrastructure services, serving as the unseen enabler of global communication and data exchange.",
"Our comprehensive network solutions integrate hardware, software, protocols, and technologies seamlessly, ensuring uninterrupted connectivity and forming the backbone for businesses and daily life.",
"From routers and cables to sophisticated software managing data traffic and security, Nfinity's proactive approach enhances the robustness and efficiency of network infrastructure. We recognize the paramount role of this intricate system as digital reliance grows, driving our commitment to continuous innovation.",
"Choose Nfinity as your trusted partner to fortify your network infrastructure, providing a resilient and secure foundation for seamless digital connections and collaboration.",
],
sublists : [
{ name:  "Network Audit", link: "network_audit" },
{ name:  "SD-WAN Configuration", link: "sdwan_configuration" },
{ name: "Dispatched Services", link: "network_infrastructure_dispatched_services" },
{ name: "Commissioning/Decommissioning", link: "commissioningde_commissioning" },
{ name:  "Network Installations and Maintenance", link: "network_installations_and_maintenance" },
]

},


StaffAugumentation: {
id : "StaffAugumentation",
url:"staff_augumentation",
title : "Staff Augumentation",
shorttitle : "Catalyze Your Business's Growth - Achieve rapid growth and enduring success with our customized talent solutions, from direct hiring to flexible staffing partnerships.",
description : [
" Nfinity Global's  Staffing Services excel in delivering tailored top-tier talent for diverse organizational needs. Our Direct Hire Placement Services prioritize lasting impact, emphasizing both skill alignment and cultural fit for permanent staff additions. Supplemental Staffing offers agile, temporary solutions, showcasing our commitment to workforce scalability in dynamic business environments.",
"Onsite & Embedded Support places seasoned experts within your organization, fostering direct collaboration and providing specialized assistance. Our Professional Resources, including short-term expert consultants in areas like IT and cybersecurity, demonstrate industry-specific expertise and adaptability.",
"With a proven track record of successful placements and client satisfaction, Nfinity Global is more than a staffing provider; we are your strategic partner in achieving business growth and success. Explore our client testimonials and case studies to witness firsthand how our flexible staffing solutions have made a tangible impact across various industries.",
],
sublists : [
{ name:  "Direct Hire Placement Services", link: "DirectHirePlacementServices" },
{ name: "Supplemental Staffing", link: "SupplementalStaffing" },
{ name: "Onsite & Embedded Support", link: "OnsiteEmbeddedSupport" },
{ name: "Professional Resources (Contract Staffing/Managed Staffing)", link: "ProfessionalResources" }
]
},

// *************Engineering and IT Services Data Start*******  //

ApplicationDevelopment : {
id : "ApplicationDevelopment",
url:"application_development",
title : "Application Support Service",
shorttitle : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua exercita nisi ut aliquip",
description : [
"Throughout our journey, Nfinity Global has consistently delivered a versatile array of IT Engineering Services designed to propel our customers' digital evolution. From DevOps and Cloud Infrastructure Services, ensuring streamlined development, efficient operations, and scalable cloud solutions, to robust Cyber Security Services fortifying digital defenses. ",
"Our dedicated Application Support Services guarantee the seamless operation of applications, while our Data Management & Migration Services ensure secure and efficient handling of data across platforms. ",
"At Nfinity Global, we've remained committed to offering a holistic approach, collaborating with our clients to build resilient and future-ready IT infrastructures, positioning their organizations for success in the dynamic digital landscape."
]
},

DevOps : {
id : "DevOps",
url:"devOps",
title : "DevOps",
shorttitle : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua exercita nisi ut aliquip",
description : [
"Throughout our journey, Nfinity Global has consistently delivered a versatile array of IT Engineering Services designed to propel our customers' digital evolution. From DevOps and Cloud Infrastructure Services, ensuring streamlined development, efficient operations, and scalable cloud solutions, to robust Cyber Security Services fortifying digital defenses. ",
"Our dedicated Application Support Services guarantee the seamless operation of applications, while our Data Management & Migration Services ensure secure and efficient handling of data across platforms. ",
"At Nfinity Global, we've remained committed to offering a holistic approach, collaborating with our clients to build resilient and future-ready IT infrastructures, positioning their organizations for success in the dynamic digital landscape."
],
description1 : {
content : `
DevOps is a set of practices that combines software development and IT operations to deliver software 
products faster, more reliably, and more securely. DevOps services are the services that help businesses 
adopt and implement DevOps culture, tools, and processes. At Nfinity Global we have the expertise, experience, 
and resources to help our clients achieve their DevOps goals.
`,
// <h1>Some of the benefits of DevOps services are:</h1>
DevOpsservices : [
"Faster time to market: Accelerate software delivery cycle by automating and streamlining the development, testing, deployment, and monitoring stages.",
"Higher quality and reliability: Helps businesses improve the quality and reliability of their software products by implementing continuous integration, continuous delivery, continuous testing, and continuous feedback mechanisms.",
"Lower costs and risks: DevOps services can help businesses reduce the costs and risks associated with software development and maintenance by optimizing the use of resources, minimizing errors, and enhancing security.",
"Better collaboration and innovation: DevOps services can help businesses foster a culture of collaboration and innovation among their development and operations teams by breaking down the silos, facilitating communication, and encouraging experimentation."
],
// <h1>Some of the DevOps services that Nfinity Global offers our customers are:</h1>
NfinityGlobal : [
"DevOps assessment and strategy: This service involves analyzing the current state of the client’s software development and delivery processes, identifying the gaps and challenges, and defining the DevOps vision, roadmap, and metrics.",
"DevOps implementation and migration: This service involves designing, building, and deploying the DevOps solution, integrating the existing tools and systems, and migrating the applications and data to the new environment.",
"DevOps automation and orchestration: Automating and orchestrating the various tasks and workflows involved in the software delivery cycle, such as code generation, code review, code testing, code deployment, and code monitoring.",
"DevOps security and compliance: Ensuring that the software products and processes comply with the relevant standards and regulations, such as ISO, PCI, HIPAA, GDPR, etc. This service also involves implementing security measures, such as encryption, authentication, authorization, auditing, and logging, to protect the software products and data from unauthorized access and threats.",
"DevOps training and support: Providing training and support to the client’s development and operations teams on the DevOps tools, techniques, and best practices. Providing ongoing maintenance and troubleshooting for the DevOps solution."
]
}
},

DataManagementMigration : {
id : "DataManagementMigration",
url:"data_management_migration",
title : "Data Management & Migration Services",
shorttitle : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua exercita nisi ut aliquip",
description : {
content : `
At Nfinity Global, we understand that effective data management and migration are critical components 
for the success of any business. In today's fast-paced digital landscape, organizations need agile and
reliable solutions to handle their ever-growing volumes of data. Our Data Management & Migration services are designed to 
empower your business by ensuring that your data is secure, accessible, and optimized for performance.
`,
// <h1>Our Services</h1>
datamanagment : {
// 1. . Data Architecture and Modeling::
DAM : [
"Craft a robust data architecture tailored to your business needs.",
"Develop efficient data models to enhance data organization and accessibility."
],
// 2. Data Governance and Quality:
DGQ : [
"Establish governance frameworks to ensure data integrity and compliance.",
"Implement data quality measures for accurate and reliable information."
],
// 3. Master Data Management:
MDM : [
"Centralize and manage critical business data for consistency across your organization.",
"Streamline processes with a single, authoritative source of truth."
],
// 4. Data Security and Privacy:
DSP : [
"Implement robust security measures to safeguard sensitive information.",
"Ensure compliance with data protection regulations and industry standards."
]
},
// Data Migration:
datamigration : {
// 1. Migration Planning and Strategy:
MPs : [
"Assess current data landscape and formulate a comprehensive migration strategy.",
"Minimize downtime and disruptions with meticulous planning and execution."
],
// 2. ETL (Extract, Transform, Load) Services:
ETL : [
"Efficiently extract data from source systems.",
"Transform and optimize data for seamless integration into the target environment."
],
// 3.Cloud Data Migration:
CDM : [
"Seamlessly transition to cloud-based solutions for enhanced scalability and flexibility.",
"Migrate data to popular cloud platforms such as AWS, Azure, and Google Cloud."
],
//4. Legacy System Migration:
LSM : [
"Upgrade and migrate data from outdated systems to modern, efficient platforms.",
"Preserve data integrity and functionality during the migration process."
]

},
content2 : [
"Transform your data into a strategic asset with Nfinity Global. Contact us today for a consultation and let us guide you through the journey of efficient data management and migration.",
"Empower your business with data, powered by Nfinity Global."
]
}
},

CyberSecurity :{
id : "CyberSecurity",
url:"cyber_security",
title : "Cyber Security Services",
shorttitle : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua exercita nisi ut aliquip",
description : {
content : [
"At Nfinity Global, we offer a comprehensive range of security solutions for your business needs.",
"Whether you need security assessment, protection, or remediation, we have the expertise and experience to deliver the best results and ensure compliance with industry standards."
],
//Our security services include:
securityservice: [
"Vulnerability assessment and penetration testing: We scan your network, systems, and applications for potential weaknesses and simulate real-world attacks to identify and remediate any security gaps.",
"Managed security services: We monitor and manage your security infrastructure, such as firewalls, VPNs, anti-virus, endpoint security, and more, to provide you with 24/7 protection and threat detection and response.",
"Security awareness training: We educate your employees on the best practices and latest trends in cybersecurity, such as how to spot and avoid phishing, ransomware, data breaches, and other cyberattacks.",
"Security consulting and auditing: We provide you with expert advice and guidance on how to improve your security posture and align it with your business goals and objectives. We also conduct security audits and reports to assess your compliance with relevant regulations and standards.",
"Cloud security and compliance: We help you secure your cloud infrastructure and applications and ensure compliance with relevant standards and regulations.",
"Identity and access management: We help you manage and control the access rights of your users and devices to your resources and data.",
],
content2 : `
We have a team of certified and experienced security professionals who use the latest tools and 
technologies to provide you with the best security solutions. We also offer customized security services 
to suit your specific needs.
`
}
},

CloudInfrastructure : {
id : "CloudInfrastructure",
url:"cloud_infrastructure",
title : "Cloud Infrastructure Services",
shorttitle : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua exercita nisi ut aliquip",
description : {
content : `
Through our Cloud Infrastructure Services, we provide on-demand access to computing 
resources, storage, networking, and virtualization. We help you to build, deploy, and
manage your applications and data in the cloud, without the hassle of maintaining your 
own physical infrastructure. Whether you need a private, public,
or hybrid cloud solution, we have the expertise and the tools to meet your needs.
`,
//Some of the benefits of choosing Cloud Infrastructure Services are:
services : [
"Cost savings: You only pay for what you use, and you can scale up or down as needed.",
"Flexibility: You can choose from a variety of cloud services and delivery models and customize them to suit your requirements.",
"Reliability: You can rely on global network of data centers, high availability, and redundancy features.",
"Security: You can trust inherent cloud security measures and compliance standards, and protect your data and applications with encryption, firewalls, and backup options.",
"Innovation: You can leverage our cloud computing expertise in latest technologies and service capabilities to accelerate your digital transformation and business growth."
],
content2 : `
If you want to learn more about Cloud Infrastructure Services, please reach out 
and We would love to hear from you and help you with your cloud journey.
`
},
},

// *************Engineering and IT Services Data End *******  //

ITManagedServiceDesk : {
id : "ITManagedServiceDesk",
url:"it_managed_service_desk",
title : "IT Managed Service Desk",
shorttitle : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua exercita nisi ut aliquip",
description : {
content : [
"Content will be posted soon..",
]
}
},
OnsiteRemottLevel2Support : {
id : "OnsiteRemottLevel2Support",
url:"on_site_remott_level_2_support",
title : "On-site & Remote Level 2 Support",
shorttitle : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua exercita nisi ut aliquip",
description : {
content : [
"Content will be posted soon..",
]
}
},

NetworkOperation247 : {
id : "NetworkOperation247",
url:"network_operation_247",
title : "24x7 NetworkOperation",
shorttitle : "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
description : {
content : [
"Content will be posted soon..",
]
}
},

ApplicationMonitoring247 : {
id : "ApplicationMonitoring247",
url:"application_monitoring_247",
title : "24x7 Application Monitoring",
shorttitle : "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
description : {
content : [
"Content will be posted soon..",
]
}
},

NetworkOperationsCenter : {
id : "NetworkOperationsCenter",
url:"network_operations_center",
title : "Network Operations Center",
shorttitle : "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
description : {
content : [
"Content will be posted soon..",
]
}
},

AVManagedServices247 : {
id : "AVManagedServices247",
url:"avmanaged_services_247",
title : "24x7 AV Managed Services",
shorttitle : "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
description : {
content : [
"Content will be posted soon..",
]
}
},

ManagedNetworkInfrastructure : {
id : "ManagedNetworkInfrastructure",
url:"managed_network_infrastructure ",
title : "Managed Network Infrastructure",
shorttitle : "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
description : {
content : [
"Content will be posted soon..",
]
}
},

ITAssetDeploymentServices : {
id : "ITAssetDeploymentServices",
url:"itas_set_seployment_services",
title : "IT Asset Deployment Services",
shorttitle : "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
description : {
content : [
"Content will be posted soon..",
]
}
},


RefreshUpgrade : {
id : "RefreshUpgrade",
url:"refresh_upgrade",
title : "Refresh Upgrade",
description : {
content : `
In today's dynamic business landscape, effective communication is paramount. At NFINITY Global,
we understand the pivotal role that cutting-edge audio-video
solutions play in enhancing collaboration, productivity, and overall workplace experience.
`,
// Why Refresh? 
content2 : [
"Is your current audio-video setup struggling to keep up with the demands of modern communication?",
"It might be time for a refresh. Outdated hardware and software ",
"can hinder the seamless flow of information, leading to missed opportunities and reduced efficiency.",
],
// Our Approach: A Seamless Integration of Refresh and Upgrade 
content3 : `
At NFINITY Global, we specialize in providing comprehensive Audio Video Services designed 
to breathe new life into your communication infrastructure. Our focus is 
on refreshing both hardware and software components while ensuring a smooth and efficient upgrade process.
`,
// Key Benefits of our Audio Video Services: 
services : [
"State-of-the-Art Technology: We bring the latest audio-video hardware and software solutions to your doorstep, ensuring that your communication tools are on par with industry standards.",
"Enhanced Collaboration: Our services go beyond mere upgrades; we tailor solutions that foster seamless collaboration. Experience crystal-clear audio, high-definition video, and intuitive interfaces that empower your team to communicate effortlessly.",
"Future-Proofing: We don't just meet your current needs; we anticipate future requirements. Our solutions are designed with scalability and adaptability in mind, ensuring your investment stands the test of time."
],
//Why Choose NFINITY Global? 
nfinityglobal : [
"Expertise: Our team comprises seasoned professionals with a deep understanding of audio-video technologies, guaranteeing solutions that align with your unique requirements.",
"Efficiency: We execute hardware and software upgrades with minimal disruption to your daily operations, ensuring a swift and hassle-free transition.",
"Client-Centric Approach: Your satisfaction is our priority. We work closely with you to tailor solutions that meet your specific needs and exceed your expectations."
],
// Let's Elevate Your Audio Video Experience Together!
}
},

WhitegloveMeetingEventSupport : {
id : "WhitegloveMeetingEventSupport",
url:"whiteglove_meeting_event_support",
title : "White-glove Meeting & EventSupport",
description : {
content : `
At NFINITY Global, we recognize that audio and video play a crucial role
in creating immersive and impactful events. Whether you're hosting a corporate m
eeting, conference, or a special occasion, our AV Services with
a White Glove touch and Event Support are tailored to exceed your expectations.`,
// Why White Glove Service? 
services : `
We understand that every event is unique and requires a personalized touch. 
Our White Glove Service ensures a meticulous and hands-on approach, treating each 
event with the care and attention it deserves. From the initial planning stages to execution,
our dedicated team is committed to delivering a seamless and stress-free experience.`,
//Key Features of our AV Services: White Glove & Event Support: 
features : [
"Customized Solutions: We work closely with you to understand the specific needs of your event. Our team crafts tailor-made AV solutions that align with your goals, venue, and audience.",
"Professional Setup and Installation: Experience the luxury of our White Glove Service as our skilled technicians handle every aspect of setup and installation, leaving you free to focus on the success of your event.",
"On-site Support: Our commitment doesn't end with the installation. Our experienced event support team is present on-site, ensuring that your audio-video components run seamlessly throughout the event.",
"Quality Assurance: We guarantee the highest standard of audio and video quality, providing a captivating and immersive experience for your audience."
],
//Why Choose NFINITY Global?
nfinityglobal : [
"Expertise: Benefit from the expertise of our AV professionals who bring a wealth of experience in managing a diverse range of events.",
"Reliability: Trust in our commitment to delivering on time and exceeding your expectations, creating an event that leaves a lasting impression.",
"Scalability: Whether your event is an intimate gathering or a large-scale production, our services are scalable to meet your unique requirements."
],
//Let's Transform Your Events Together!
}
},

DataCabling : {
id : "DataCabling",
url:"data_cabling",
title : "Data Cabling",
description : {
content : `
In an era driven by connectivity, a robust and efficient data-cabling infrastructure 
is the backbone of seamless audio and video experiences. At NFINITY Global, 
we specialize in providing cutting-edge Audio Video Services with a dedicated focus on
Data Cabling solutions.
`,
//Why Prioritize Data Cabling in AV Services? 
content2 : `
A strong and reliable data cabling foundation is essential for the optimal performance
of audio and video systems. Our AV Services with expert Data Cabling solutions ensure that your technology not only 
meets the demands of today but is also prepared for the advancements of tomorrow.
`,
//Key Features of our AV Services - Data Cabling:
services : [
"Customized Cabling Solutions: Tailored to your specific needs, our data cabling solutions are designed to accommodate the unique requirements of your audio and video setups.",
"High-Speed Connectivity: Our expertise lies in providing data cabling that supports high-speed data transfer, minimizing latency and ensuring a smooth audio and video streaming experience.",
"Scalability: Whether you're a growing business or planning for future expansions, our data cabling solutions are scalable to adapt to your evolving connectivity needs.",
"Reliability: Count on our reliable and durable cabling infrastructure to minimize downtime and maintain consistent performance for your AV systems.",
],
//Why Choose NFINITY Global?
nfinityglobal : [
"Expert Technicians: Our team of skilled technicians specializes in the installation and maintenance of data cabling infrastructure, ensuring precision and reliability.",
"Comprehensive Solutions: Beyond traditional audio and video services, we offer end-to-end solutions that encompass data cabling to provide a seamless and integrated experience.",
"Future-Ready Connectivity: Embrace the future confidently with our forward-thinking data cabling solutions, designed to support the latest advancements in audio and video technology."
],
content3 : `
Upgrade your audio and video experience with NFINITY Global's AV Services - Data Cabling. 
Whether you're setting up a new office or upgrading your existing infrastructure, our expert 
solutions will empower your connectivity for a more efficient and reliable AV system. 
`,
}
},

PreventativeMaintenance : {
id : "PreventativeMaintenance",
url:"preventative_maintenance",
title : "Preventative Maintenance",
description : {
content :  `
At NFINITY Global, we understand that your audio and video systems are critical components of your daily operations. 
To ensure a seamless and uninterrupted experience, we offer specialized Audio Video Services with a primary focus on Preventive Maintenance.
`,
// The Importance of Preventive Maintenance in AV Services: 
content2 : `
Preventive Maintenance is the key to sustaining the peak performance of your audio 
and video equipment. Rather than waiting for issues to arise, our proactive approach involves 
regular inspections, updates, and 
optimizations to prevent potential problems, ensuring your systems consistently deliver optimal results.
`,
// Key Features of our AV Services - Preventive Maintenance: 
services : [
"Scheduled Inspections: Our team conducts regular inspections of your audio and video systems to identify and address potential issues before they impact your operations.",
"Firmware and Software Updates: Stay ahead of the curve with timely updates to firmware and software, ensuring your equipment is equipped with the latest features and security patches.",
"Cleaning and Calibration: We keep your equipment in pristine condition through thorough cleaning and precise calibration, preserving the quality of your audio and video output.",
"Performance Optimization: Our preventive maintenance services focus on optimizing the performance of your AV systems, maximizing their efficiency and lifespan.",
],
//Why Choose NFINITY Global?
nfinityglobal : [
"Proactive Approach: We believe in preventing problems before they occur, saving you time and resources by minimizing the risk of downtime.",
"Expert Technicians: Our skilled technicians are trained to identify potential issues and implement preventive measures to maintain the reliability of your AV systems.",
"Tailored Maintenance Plans: Every business is unique. We work with you to create a customized preventive maintenance plan that aligns with your specific audio and video requirements."
],
}
},

DispatchedServicesTwo : {
id : "DispatchedServicesTwo",
url:"dispatched_services_two",
title : "Dispatched Services",
description : {
content :  `
In the fast-paced world of audio and video technology, timely resolution of issues is paramount. 
At NFINITY Global, we offer specialized Audio Video Services with a unique emphasis on 
Dispatched Support, ensuring that your systems are always up and running when you need them.
`,
// TThe Power of Dispatched Services in AV Solutions:
content2 : `
When technical issues arise, you need a responsive and efficient support system in place. Our Dispatched Services go beyond conventional support, providing on-demand assistance to address challenges promptly, minimizing downtime and ensuring uninterrupted audio and video experiences.
`,
// Key Features of our AV Services - Dispatched Support: 
services : [
"Rapid Response: Our dedicated team is ready to spring into action, dispatching skilled technicians to your location swiftly when issues occur.",
"Remote Troubleshooting: Leverage our advanced remote support capabilities for quick issue identification and resolution, minimizing the need for physical dispatch when possible.",
"24/7 Availability: Technical issues don't adhere to a schedule, and neither do we. Our Dispatched Services are available round the clock to meet your urgent needs.",
"Equipment Replacement: In cases where immediate on-site repair isn't feasible, we offer efficient equipment replacement services to ensure continuity."
],
//Why Choose NFINITY Global?
nfinityglobal : [
"Prompt Problem Resolution: Our commitment to rapid response ensures that your audio and video systems are back on track swiftly, reducing disruptions to your operations.",
"Skilled Technicians: Our dispatched support team consists of skilled technicians with expertise in troubleshooting a wide range of audio and video equipment.",
"Customized Service Level Agreements (SLAs): Tailor our Dispatched Services to your specific requirements with flexible SLAs that align with your business needs."
],
//Experience Unmatched Support with Dispatched AV Services!
},
},

NetworkAudit : {
id : "NetworkAudit",
url:"network_audit",
title : "Network Audit",
shorttitle : "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
description : {
content : [
"Content will be posted soon..",
],
}
},

SDWANConfiguration : {
id : "SDWANConfiguration",
url:"sdwan_configuration",
title : "SD-WAN Configuratio",
shorttitle : "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
description : {
content : [
"Content will be posted soon..",
],
}
},

NetworkInfrastructureDispatchedServices : {
id : "NetworkInfrastructureDispatchedServices",
url:"network_infrastructure_dispatched_services",
title : "Dispatched Services",
shorttitle : "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
description : {
content : [
"Content will be posted soon..",
],
}
}, 

CommissioningDecommissioning : {
id : "CommissioningDecommissioning",
url:"commissioningde_commissioning",
title : "Commissioning/ Decommissioning",
shorttitle : "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
description : {
content : [
"Content will be posted soon..",
],
}
},

NetworkInstallationsandMaintenance : {
id : "NetworkInstallationsandMaintenance",
url:"network_installations_and_maintenance",
title : "Network Installations and Maintenance",
shorttitle : "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
description : {
content : [
"Content will be posted soon..",
],
}
},

DirectHirePlacementServices : {
id : "DirectHirePlacementServices",
url:"direct_hire_placement_services",
title : "Direct Hire Placement Services",
shorttitle : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua exercita nisi ut aliquip",
description : {
content : [
"Content will be posted soon..",
]
}
},

SupplementalStaffing : {
id : "SupplementalStaffing",
url:"supplemental_staffing",
title : "Supplemental Staffing",
shorttitle : "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
description : {
content : [
"Content will be posted soon..",
],
}
},

OnsiteEmbeddedSupport : {
id : "OnsiteEmbeddedSupport",
url:"onsite_embedded_support",
title : "Onsite & Embedded Support",
shorttitle : "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
description : {
content : [
"Content will be posted soon..",
],
}
},

ProfessionalResources : {
id : "ProfessionalResources",
url:"professional_resources",
title : "Professional Resources",
shorttitle : "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
description : {
content : [
"Content will be posted soon..",
],
}
},

SolutionsAudioVisual: {
id : "SolutionsAudioVisual",
url:"solutions_audio_and_visual",
title : "Audio And Visual",
shorttitle : "Charting New Horizons with Nfinity's Solutions – Pioneering Tailored Audio-Visual Experiences for Enhanced Communication.",
description : [
"At Nfinity, we take pride in our unique approach to Audio and Video solutions. Our Unified Communications systems are designed with the future in mind, ensuring seamless integration and collaboration. Our state-of-the-art Video Walls transform spaces into interactive hubs, fostering communication and creativity. The Design & Build of our Audio and Video systems is a testament to our commitment to creating immersive experiences that are not just heard, but felt.",
"We believe that innovation is key to success, and we continuously strive to enhance our offerings. Our attention to detail and dedication to delivering personalized solutions are what make us stand out. We're dedicated to providing our clients with the tools they need to communicate effectively and efficiently, without drawing direct comparisons. Our focus is on elevating your business communication to the next level with cutting-edge technology and unparalleled service."
],
sublists : [
{ name: "Unified Communications", link: "UnifiedCommunications" },
{ name: "Video Walls", link: "VideoWalls" },
{ name: "Design & Build", link: "DesignBuild" },
]
},

SolutionsNetworkInfrastructure: {
id : "SolutionsNetworkInfrastructure",
url:"solutions_network_infrastructure",
title : "Network Infrastructure",
shorttitle : "Elevate Your Network with Nfinity: Designing, Building, and Enhancing Your Infrastructure for the Digital Future.",
description : [
"At Nfinity, we offer a comprehensive suite of solutions that encompass the planning, designing, and optimization of network infrastructure. Our services are tailored to meet the unique needs of your business, ensuring that your network is not only built on a solid foundation but is also primed for future growth and innovation.",
"Our team is skilled in rolling out new networks, upgrading existing ones, and migrating systems with minimal disruption. We focus on creating a scalable and flexible network environment that can adapt to the ever-evolving technological landscape.",
"By partnering with Nfinity, you gain access to our expertise in network infrastructure, which will guide you through every step of the process. From initial planning to final optimization, we're dedicated to enhancing your network's performance, security, and reliability.",

],
sublists : [
{ name: "Plan, Design & Optimize your network Infrastructure", link: "PlanDesignOptimizeyournetworkInfrastructure" },
{ name: "Build, Roll-out, Upgrade, Operate, Maintain and Transfer network", link: "BuildRolloutUpgradeOperateMaintain" },
]
},

}

export default servicedata;