import React from 'react'
import ManagedServicesImg2 from "../images/ManagedServices-img-2.png";
import { NavLink } from "react-router-dom";


function Revolutionize() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className="Revolutionize-r-bg">
        <div className="wrapper-4">
         <div className="Revolutionize-wrap">
          <div className="Revolutionize-1">
            <h5>Revolutionize your digital experience with cutting-edge IT solutions</h5>
            <p>Our expert team is here to elevate your business. Let's innovate together. Get in touch for unparalleled IT services.</p>
           
            <div><NavLink to="../ContactUs" onClick={scrollToTop} className="submit-1">Reach Us</NavLink></div>

          </div>
          <div className="Revolutionize-2"><img src={ManagedServicesImg2} alt="ManagedServicesImg2" /></div>

         </div>
        </div>
      </div>
    </div>
  )
}

export default Revolutionize
