import React from 'react'
import InnerShapeOne from '../images/inner-banner-shape.png';
import InnerShapeTwo from '../images/shape-5.png';
import { NavLink } from 'react-router-dom'

import data from './npatners'


const aboutCard = data.map((aboutObj)=>{
  const {id, title,url} = aboutObj;
  return(
      
       <div key={id} className='oc-l'> <img src={url} alt={title}/></div>

  )
})


function Patners() {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
}

  return (
    <div>
    <div className='inner-banner-wrap m-t-1  OurCustomers-banner'>
      <div className='wrapper'>
        <h1 className='banner-text-center'><b>Partners </b></h1>
      </div>
       
    </div>

    <div className='inner-page-bg-1'>
      <div className='wrapper'>
      <div className='our-clients-list-2'>
      {aboutCard}
      </div>
      </div>
    </div>
    
    
     

</div>
  )
}

export default Patners
