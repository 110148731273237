import React from "react";
import InnerShapeOne from "../images/inner-banner-shape.png";
import InnerShapeTwo from "../images/shape-5.png";
import ManagedServicesImg from "../images/about-inner-img-1.png";
import { NavLink } from "react-router-dom";
import WeareBestSlider from "./WeareBestSlider";
import Shape4 from "../images/shape-4a.png";

import Passion from "../images/Passion.png";
import Innovation from "../images/Innovation.png";
import ClientFocus from "../images/Client-Focus.png";
import Teamwork from "../images/Teamwork.png";
import ServiceQuality from "../images/Service-Quality.png";

function AboutUs() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div className="inner-banner-wrap m-t-1 AboutUs-banner">
        <div className="wrapper">
          <h1 className="banner-text-center">
            <b>About </b> Us
          </h1>
        </div>
      </div>

      <div className="inner-page-bg-1 white-bg-1">
        <div className="wrapper">
          <div className="about-partner-main">
            <div className="about-partner-2">
              <img src={ManagedServicesImg} alt="ManagedServicesImg" />
            </div>
            <div className="about-partner-1  ">
              <div className="heading-2 w-80-1">
                <h2>
                  Business toward <b>Success</b>
                </h2>
              </div>
              <p>
              At Nfinity Global, we are committed to being your trusted partner for digital transformation. With over a decade of experience and 15+ office and delivery locations we are serving over 50+ global customers with their technology and digitalization needs. We take pride in our customer-centric approach, curating customized solutions tailored to meet your unique business needs.</p>
              <p>Our technical expertise and focus on cutting-edge technologies such as AI/ML, Generative AI, and cloudification allow us to optimize performance, ensure continuous improvement, and support sustainability for your long-term success.</p>
              <p>Our standard service processes based on global standards ensure high-quality, cost-effective services that meet your business goals. With our unwavering commitment to providing exceptional customer value and employee experiences, let us help you take your business to new heights.</p>
              {/* <NavLink
                to="../ClientExperience"
                onClick={scrollToTop}
                className="submit-1 m-t-2"
              >
                Learn More
              </NavLink> */}
            </div>
          </div>
        </div>
      </div>
      <div className="mission-bg">
        <div className="wrapper">
          <h2>
            Our <b>Mission</b>
          </h2>
          <p>
            Our mission and vision to provide Information Technology Solutions
            and Managed Services that simplify and efficient technology, enhance
            communication and improve the way people work, learn, and live.
          </p>
        </div>
      </div>

      <div className="best-bg-white">
        <div className="wrapper">
          <div className="heading-center-2">
            <h2>
              The Pillars of Our Success: <b>Core Values</b>
            </h2>
            <p>
              Your needs, our expertise: A concise look at our diverse service
              portfolio.
            </p>
          </div>

          <div className="home-services-border-wrap">
            <div className="home-services-border-1">
              <NavLink to="EngineeringandITServices" onClick={scrollToTop}>
                <img src={Passion} alt={Passion} />
                <h3>Passion</h3>
              </NavLink>
            </div>
            <div className="home-services-border-1">
              <NavLink to="ManagedServices" onClick={scrollToTop}>
                <img src={Innovation} alt={Innovation} />
                <h3>Innovation</h3>
              </NavLink>
            </div>
            <div className="home-services-border-1">
              <NavLink to="AudioandVisual" onClick={scrollToTop}>
                <img src={ClientFocus} alt={ClientFocus} />
                <h3>Client-Focus</h3>
              </NavLink>
            </div>
            <div className="home-services-border-1">
              <NavLink to="NetworkInfrastructure" onClick={scrollToTop}>
                <img src={Teamwork} alt={Teamwork} />
                <h3>Teamwork</h3>
              </NavLink>
            </div>

            <div className="home-services-border-1">
              <NavLink to="StaffingAugumentation" onClick={scrollToTop}>
                <img src={ServiceQuality} alt={ServiceQuality} />
                <h3>Service Quality</h3>
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="mission-bg ">
        <div className="wrapper">
          <h2>
            Why we <b>Excel</b> in what we do
          </h2>
          <p>
            Our Professional Prowess is not just a claim; it's a Commitment we
            Uphold.
          </p>
          <div className="m-t-1a">
            <WeareBestSlider />
          </div>
        </div>
      </div>

      {/* <div className='inner-page-bg-1'>
      <div className='wrapper'>
        <div className='two-col-1-main'>
          <div className='two-col-1-1'>
            <p>Nfinity provides an extensive and adaptable suite of Managed IT Services designed to enhance both customer and employee experiences. </p>
            <p>Our commitment is to keep your IT infrastructure at the forefront, covering everything from acquiring cutting-edge hardware to seamlessly integrating innovative software. Our responsive help desk ensures quick and efficient support, complemented by customized engineering solutions crafted to propel your business toward success. </p>
            <p>At Nfinity, our unwavering dedication to security takes precedence. Through our comprehensive security services and solutions, we implement robust measures to safeguard against cyber threats, ensuring the uninterrupted flow of business operations.</p>
            <p>Serving as your dedicated IT partner, Nfinity is committed to fostering your growth, guiding you through the digital landscape with confidence.</p>
            <hr></hr>
            <h3>Our Mission </h3>
            <p>To create IT solutions and managed services that enhance connectivity, streamline businesses, and empower individuals to achieve their dreams through innovation and efficiency </p>
            <h3>Our Vision</h3>
            <p>To be the driving force in leveraging technology to bring people closer, businesses into the future, and dreams within reach.</p>

            
            <NavLink to="../ContactUs" onClick={scrollToTop} className='submit-1 m-t-2'>Contact Us</NavLink>
          </div>
          
        </div>

      </div>

    </div> */}

      {/* <div className='strip-100'>
        <img src={InnerShapeTwo} alt='InnerShapeTwo' />
      </div> */}
      <div className="inner-page-bg-2">
        {/* <div className='wrapper'>
     
      <div className='nm'>
      <ul>
      <li><NavLink to="../EngineeringandITServices" onClick={scrollToTop}>Engineering and IT Services</NavLink></li>
      <li><NavLink to="../ManagedServices" onClick={scrollToTop}>Managed Services</NavLink></li>
      <li><NavLink to="../AudioandVisual" onClick={scrollToTop}>Audio And Visual</NavLink></li>
      <li><NavLink to="../NetworkInfrastructure" onClick={scrollToTop}>Network Infrastructure </NavLink></li>
      <li><NavLink to="../StaffingAugumentation" onClick={scrollToTop}>Staff Augumentation</NavLink></li>

      </ul>
      </div>
     
      </div> */}

        {/* <div className="best-bg">
        <div className="wrapper">
          <div className="heading-center-2">
            <h2>
              Why we are <b>Excel</b> in What We Do?
            </h2>
            <p>
            Our Professional Prowess is not just a claim; it's a Commitment we Uphold.
            </p>
          </div>
          <WeareBestSlider />
        </div>
       
      </div> */}
      </div>
    </div>
  );
}

export default AboutUs;
