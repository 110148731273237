import React from 'react'
import InnerShapeOne from '../images/inner-banner-shape.png';
import InnerShapeTwo from '../images/shape-5.png';
import { NavLink } from 'react-router-dom'
import MapImg1 from '../images/map.png';

function OurFootPrints() {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
}

  return (
    <div>
    <div className='inner-banner-wrap m-t-1'>
      <div className='wrapper'>
        <h1 className='banner-text-center'><b>Our </b> FootPrints</h1>
      </div>
       
    </div>

    <div className='inner-page-bg-1'>
    <div className='wrapper'>
        <div className='map-sec'>
          <h2>Our Team is having Worldwide Presence</h2>
        <img src={MapImg1} alt={MapImg1} />
        </div>
        </div>

    </div>
    
    
     

</div>
  )
}

export default OurFootPrints
