import React from "react";
import InnerShapeOne from "../images/inner-banner-shape.png";
//import CSLogo1img1 from "../images/casestudies-img-1.png";

function CareerPostTwo() {
  return (
    <div className=" fade-in">
      <div className="inner-banner-wrap m-t-1">
        <div className="wrapper">
          <h1 className="banner-text-center">
            <b>Career</b> 
          </h1>
        </div>
        
      </div>
      <div className="inner-bg-2-main">
        <div className="inner-bg-2">
          <div className="wrapper-2">
            <h2>
            Regional IT Admin:
            </h2>
            
            <h3>
            Qualifications:
            </h3>
            <p>A solid understanding of RIT basics and practical experience.</p>
            <p>Proficiency in Microsoft Excel and PowerPoint, including advanced features and functions.</p>
            <p>Strong analytical, problem-solving, and critical-thinking skills.</p>
            <p>Excellent communication skills, both verbal and written.</p>
            <p>A commitment to promoting diversity and inclusion in the workplace.</p>
            <p>Ability to work collaboratively in a team-oriented environment.</p>
            <h3>Location: </h3>
            <p>This position is based Gurgaon, and candidates from diverse backgrounds are strongly
encouraged to apply.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CareerPostTwo;
