import React from "react";
import Revolutionize from "./Revolutionize";
import { NavLink } from "react-router-dom";
import servicedata from "./servicedata.js";

function DevOps() {
  const data = servicedata.DevOps;
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="fade-in">
      <div className="inner-banner-wrap m-t-1">
        <div className="wrapper">
          <h1 className="banner-text-center">{data.title}</h1>
          <p>{data.shorttitle}</p>
        </div>
      </div>
      <div className="inner-services-r-bg">
        <div className="wrapper">
        <p>{data.description}</p>
        <p>{data.description1.content}</p>
          <h3>Some of the benefits of DevOps services are:</h3>
          {data.description1.DevOpsservices.map((DevOpsservices, index) => (
            <p key={index}>{DevOpsservices}</p>
          ))}
          <h3>Some of the DevOps services that Nfinity Global offers our customers are: </h3>
          {data.description1.NfinityGlobal.map((NfinityGlobal, index) => (
            <p key={index}>{NfinityGlobal}</p>
          ))}
        </div>
      </div>
    </div>
  );
}
export default DevOps;
