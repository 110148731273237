import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import InnerShapeOne from "../images/inner-banner-shape.png";
const OurTeam = () => {
  const products = [
    {
      id: 1,
      name: "Vijay Kammela",
      designation: "CEO",
      shortdescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      thumbnail: "./images/Vijay-Kammela.png",
    },
    {
      id: 2,
      name: "Madhav Rudraraju",
      designation: "Managing Director",
      shortdescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      thumbnail: "./images/Madhav.jpg",
    },
    {
      id: 3,
      name: "Mindy Vu",
      designation: "Director - Operations",
      shortdescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      thumbnail: "images/Mindy.png",
    },

    {
      id: 4,
      name: "Sumit Shaw",
      designation: "VP - Managed Services",
      shortdescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      thumbnail: "images/sumith.png",
    },
    {
      id: 5,
      name: "Anil Myneni",
      designation: "AVP - Managed Services",
      shortdescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      thumbnail: "images/Anil.jpg",
    },
    {
      id: 6,
      name: "Krishna Gogineni",
      designation: "AVP - Engineering Services",
      shortdescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500ss",
      thumbnail: "images/Krishna.jpg",
    },
    {
      id: 7,
      name: "Shanthi Rudraraju",
      designation: "Director - inance",
      shortdescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500ss",
      thumbnail: "images/Shanthi.jpg",
    },
   
    {
      id: 8,
      name: "Aparna Janapati",
      designation: "HR Manager/Talent Acquisition",
      shortdescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      thumbnail: "images/aparna.png",
    },
    {
      id: 9,
      name: "Sandhya Rudraraju",
      designation: "Head - Engagement & Culture",
      shortdescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      thumbnail: "images/Sandhya.jpg",
    },
  ];

  const [selectedProduct, setSelectedProduct] = useState(null);

  const openPopup = (product) => {
    setSelectedProduct(product);
  };

  const closePopup = () => {
    setSelectedProduct(null);
  };

  return (
    <div>
      <div className="inner-banner-wrap m-t-1">
        <div className="wrapper">
          <h1 className="banner-text-center">
            <b>Our Team </b>
          </h1>
        </div>
       
      </div>

      <div className="container">
        <div className="">

          <div class="n-team-sec">

         {/*<div key={product.id} className="OurTeam-border-1">
            <div className="OurTeam-border-1-1">
              <img
                src={product.thumbnail}
                className="card-img-top"
                alt={product.name}
              />
            </div>
            <div className="OurTeam-border-1-2">
              <h3>{product.name}</h3>
              <p>{product.designation}</p>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => openPopup(product)}
              >
                Read more
              </button>
            </div>
          </div>*/}
          
            {products.map((product) => (
              <div key={product.id} className="n-team-wrap">
                <div className="n-team-1">
                  {" "}
                  <img
                    src={product.thumbnail}
                    className="card-img-top"
                    alt={product.name}
                  />
                </div>
                <div className="n-team-2">
                  <h3>{product.name}</h3>
                  <p>{product.designation}</p>
                  <p>{product.shortdescription}</p>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => openPopup(product)}
                  >
                    Read more
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        {selectedProduct && (
          <div className="modal fade show" style={{ display: "block" }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">{selectedProduct.name}</h5>
                  <button type="button" className="close" onClick={closePopup}>
                    <span>&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="popUpImg">
                    <img
                      src={selectedProduct.thumbnail}
                      className=""
                      alt={selectedProduct.name}
                    />
                  </div>
                  <p>{selectedProduct.description}</p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OurTeam;
