import React from 'react'
import InnerShapeOne from '../images/inner-banner-shape.png';
import InnerShapeTwo from '../images/shape-5.png';
import { NavLink } from 'react-router-dom'
import CaseStudies from "./CaseStudies";

function AboutCaseStudies() {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
}

  return (
    <div>
    <div className='inner-banner-wrap m-t-1 Careers-banner'>
      <div className='wrapper'>
        <h1 className='banner-text-center'><b>CaseStudies </b></h1>
      </div>
       
    </div>

    <div className='inner-page-bg-1'>
    <div className="wrapper">
          <h2>
            Case <b>Studies</b>
          </h2>
          <p>
            Real-world success stories: Unveiling our impactful case studies
          </p>
          <CaseStudies />

          {/*<Tab/>*/}
        </div>

    </div>
    
    
     

</div>
  )
}

export default AboutCaseStudies
