import React from 'react'
import InnerShapeOne from '../images/inner-banner-shape.png';
import InnerShapeTwo from '../images/shape-5.png';
import { NavLink } from 'react-router-dom'

function CompanyNews() {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
}

  return (
    <div>
    <div className='inner-banner-wrap m-t-1 Careers-banner'>
      <div className='wrapper'>
        <h1 className='banner-text-center'><b>Company </b> News</h1>
      </div>
       
    </div>

    <div className='inner-page-bg-1'>
      <div className='wrapper'>
      <p>Nfinity provides an extensive and adaptable suite of Managed IT Services designed to enhance both customer and employee experiences. </p>
      <p>Our commitment is to keep your IT infrastructure at the forefront, covering everything from acquiring cutting-edge hardware to seamlessly integrating innovative software. Our responsive help desk ensures quick and efficient support, complemented by customized engineering solutions crafted to propel your business toward success. </p>
      <p>At Nfinity, our unwavering dedication to security takes precedence. Through our comprehensive security services and solutions, we implement robust measures to safeguard against cyber threats, ensuring the uninterrupted flow of business operations.</p><p>Serving as your dedicated IT partner, Nfinity is committed to fostering your growth, guiding you through the digital landscape with confidence.</p>
      

      </div>

    </div>
    
    
     

</div>
  )
}

export default CompanyNews
