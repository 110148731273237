import React, { useState } from 'react';

const ProductList = () => {
  const products = [
    { id: 1, name: 'Product 1', description: 'Description for Product 1' },
    { id: 2, name: 'Product 2', description: 'Description for Product 2' },
    { id: 3, name: 'Product 3', description: 'Description for Product 3' },
  ];

  const [popupContent, setPopupContent] = useState(null);

  const openPopup = (description) => {
    setPopupContent(description);
  };

  const closePopup = () => {
    setPopupContent(null);
  };

  return (
    <div>
      
      <h1 style={{marginTop : '100px'}}>Product List</h1>
      <ul>
        {products.map((product) => (
          <li key={product.id}>
            {product.name} -{' '}
            <button onClick={() => openPopup(product.description)}>
              View Description
            </button>
          </li>
        ))}
      </ul>

      {popupContent && (
        <div className="popup">
          <div className="popup-content">
            <h2>Description</h2>
            <p>{popupContent}</p>
            <button onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductList;