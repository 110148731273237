import React from 'react';
import { useEffect, useState } from "react";
import './Styles.css'
import './Navigation.css'
import Header from "./Header/Header";
import Footer from './Footer/Footer';
import AboutUs from "./Pages/AboutUs";
import Home from "./Pages/Home";
import OurServices from './Pages/OurServices';
import{ Routes, Route} from "react-router-dom";
import Preloader from './Pages/Preloader'
import ContactUs from './Pages/ContactUs'
import ManagedServices from './Pages/ManagedServices'
import EngineeringandITServices from './Pages/EngineeringandITServices'
import NetworkInfrastructure from './Pages/NetworkInfrastructure'
import AudioandVisual from './Pages/AudioandVisual'
import StaffingAugumentation from './Pages/StaffingAugumentation'
import Careers from './Pages/Careers'
import ClientExperience from './Pages/ClientExperience'
import CaseStudiesInner from './Pages/CaseStudiesInner'
import NMSP from './Pages/NMSP'
import OurTeam from './Pages/OurTeam'
import CareerPostOne from './Pages/CareerPostOne'
import CareerPostTwo from './Pages/CareerPostTwo'
import CareerPostThree from './Pages/CareerPostThree'
import ContactForm from './Pages/ContactForm'
import CaseStudyOne from './Pages/CaseStudyOne'
import CaseStudyTwo from './Pages/CaseStudyTwo'
import CaseStudyThree from './Pages/CaseStudyThree'
import ProductList from './Pages/ProductList'
import ProductListTwo from './Pages/ProductListTwo'

import ApplicationDevelopment from './Pages/ApplicationDevelopment'
import DevOps from './Pages/DevOps'



import DataManagementMigration from './Pages/DataManagementMigration'
import CyberSecurity from './Pages/CyberSecurity'
import ArchitectureDesignSetup from './Pages/ArchitectureDesignSetup'
import MaintenanceSupport from './Pages/MaintenanceSupport'
import CloudInfrastructure from './Pages/CloudInfrastructure'


import ITManagedServiceDesk from './Pages/ITManagedServiceDesk'
import OnsiteRemottLevel2Support from './Pages/OnsiteRemottLevel2Support'
import NetworkOperation247 from './Pages/NetworkOperation247'
import ApplicationMonitoring247 from './Pages/ApplicationMonitoring247'
import NetworkOperationsCenter from './Pages/NetworkOperationsCenter'
import AVManagedServices247 from './Pages/AVManagedServices247'
import ManagedNetworkInfrastructure from './Pages/ManagedNetworkInfrastructure'
import ITAssetDeploymentServices from './Pages/ITAssetDeploymentServices'


import UnifiedCommunications from './Pages/UnifiedCommunications'
import VideoWalls from './Pages/VideoWalls'
import DesignBuild from './Pages/DesignBuild'
import RefreshUpgrade from './Pages/RefreshUpgrade'
import WhitegloveMeetingEventSupport from './Pages/WhitegloveMeetingEventSupport'
import DataCabling from './Pages/DataCabling'
import PreventativeMaintenance from './Pages/PreventativeMaintenance'
import DispatchedServicesTwo from './Pages/DispatchedServicesTwo'


import PlanDesignOptimizeyournetworkInfrastructure from './Pages/PlanDesignOptimizeyournetworkInfrastructure'
import BuildRolloutUpgradeOperateMaintain from './Pages/BuildRolloutUpgradeOperateMaintain'
import NetworkAudit from './Pages/NetworkAudit'
import SDWANConfiguration from './Pages/SDWANConfiguration'
import NetworkInfrastructureDispatchedServices from './Pages/NetworkInfrastructureDispatchedServices'
import CommissioningDecommissioning from './Pages/CommissioningDecommissioning'
import NetworkInstallationsandMaintenance from './Pages/NetworkInstallationsandMaintenance'


import DirectHirePlacementServices from './Pages/DirectHirePlacementServices'
import SupplementalStaffing from './Pages/SupplementalStaffing'
import OnsiteEmbeddedSupport from './Pages/OnsiteEmbeddedSupport'
import ProfessionalResources from './Pages/ProfessionalResources'



import SeparateCareerDetailsTwo from './Pages/SeparateCareerDetailsTwo'
import SolutionsAudioandVisual from './Pages/SolutionsAudioandVisual';
import SolutionsNetworkInfrastructure from './Pages/SolutionsNetworkInfrastructure';
import OurCustomers from './Pages/OurCustomers';
import Patners from './Pages/Patners';
import CompanyNews from './Pages/CompanyNews';
import AboutCaseStudies from './Pages/AboutCaseStudies';
import OurFootPrints from './Pages/OurFootPrints';

import OurBoard from './Pages/OurBoard';
import SearchComponent from './Pages/SearchComponent';


function App() {
  //loader State
  const [isLoading, setIsLoading] = useState(true);

  useEffect (() => {
    const fakeDataFetch = () => {
      setTimeout (() => {
        setIsLoading(false);
      }, 100);
    };
    fakeDataFetch();

  },[]);

  return  isLoading ? (
    <Preloader />
    ) : (
<div className="App">
 <Header/>
  <Routes>
  <Route element={<Home/>} path="/" />
    
    <Route element={<OurBoard/>} path="/our_board" />
    <Route element={<OurFootPrints/>} path="/our_foot_prints" />
    <Route element={<AboutCaseStudies/>} path="/about_case_studies" />
    <Route element={<CompanyNews/>} path="/company_news" />
    <Route element={<Patners/>} path="/patners" />
    <Route element={<OurCustomers/>} path="/our_customers" />
    <Route element={<SolutionsAudioandVisual/>} path="/solutions_audio_and_visual" />
    <Route element={<SolutionsNetworkInfrastructure/>} path="/solutions_network_infrastructure" />




    
    <Route element={<AboutUs/>} path="/about_us" />
    <Route element={<OurServices/>} path="/our_services" />
    <Route element={<ContactUs/>} path="/contact_us" />
    <Route element={<ManagedServices/>} path="/managed_Services" />
    <Route element={<NetworkInfrastructure/>} path="/network_infrastructure" />
    <Route element={<AudioandVisual/>} path="/audio_and_visual" />
    <Route element={<StaffingAugumentation/>} path="/staffing_augumentation" />
    <Route element={<EngineeringandITServices/>} path="/engineering_and_iT_services" />
    <Route element={<Careers/>} path="/careers" />
    <Route element={<ClientExperience/>} path="/client_experience" />
    <Route element={<NMSP/>} path="/nmsp" />
    <Route element={<CaseStudiesInner/>} path="/case_studies_inner" />
    <Route element={<OurTeam/>} path="/our_team" />
    <Route element={<CareerPostOne/>} path="/career_post_one" />
    <Route element={<CareerPostTwo/>} path="/career_post_one" />
    <Route element={<CareerPostThree/>} path="/career_post_one" />

    <Route element={<CaseStudyOne/>} path="/case_study_one" />
    <Route element={<CaseStudyTwo/>} path="/case_study_two" />
    <Route element={<CaseStudyThree/>} path="/case_study_three" />
    <Route element={<ProductList/>} path="/product_list" />
    <Route element={<ProductListTwo/>} path="/productListTwo" />
    <Route element={<ContactForm/>} path="/contact_form" />
    <Route element={<ApplicationDevelopment/>} path="/application_development" />
    <Route element={<DevOps/>} path="/devOps" />

 <Route element={<DataManagementMigration/>} path="/data_management_migration" />
 <Route element={<CyberSecurity/>} path="/cyber_security" />
 <Route element={<ArchitectureDesignSetup/>} path="/architecture_design_setup" />
 <Route element={<MaintenanceSupport/>} path="/maintenance_support" />
 <Route element={<CloudInfrastructure/>} path="/cloud_infrastructure" />

 <Route element={<ITManagedServiceDesk/>} path="/it_managed_service_desk" />
 <Route element={<OnsiteRemottLevel2Support/>} path="/on_site_remott_level_2_support" />
 <Route element={<NetworkOperation247/>} path="/network_operation_247" />
 <Route element={<ApplicationMonitoring247/>} path="/application_monitoring_247" />
 <Route element={<NetworkOperationsCenter/>} path="/network_operations_center" />
 <Route element={<AVManagedServices247/>} path="/avmanaged_services_247" />
 <Route element={<ManagedNetworkInfrastructure/>} path="/managed_network_infrastructure" />
 <Route element={<ITAssetDeploymentServices/>} path="/itas_set_seployment_services" />


 <Route element={<UnifiedCommunications/>} path="/unified_communications" />
 <Route element={<VideoWalls/>} path="/video_walls" />
 <Route element={<DesignBuild/>} path="/design_build" />
 <Route element={<RefreshUpgrade/>} path="/refresh_upgrade" />
 <Route element={<WhitegloveMeetingEventSupport/>} path="/whiteglove_meeting_event_support" />
 <Route element={<DataCabling/>} path="/data_cabling" />
 <Route element={<PreventativeMaintenance/>} path="/preventative_maintenance" />
 <Route element={<DispatchedServicesTwo/>} path="/dispatched_services_two" />


 <Route element={<PlanDesignOptimizeyournetworkInfrastructure/>} path="/plan_design_optimizeyournetwork_infrastructure" />
 <Route element={<BuildRolloutUpgradeOperateMaintain/>} path="/build_rollout_upgrade_operate_maintain" />
 <Route element={<NetworkAudit/>} path="/network_audit" />
 <Route element={<SDWANConfiguration/>} path="/sdwan_configuration" />
 <Route element={<NetworkInfrastructureDispatchedServices/>} path="/network_infrastructure_dispatched_services" />
 <Route element={<CommissioningDecommissioning/>} path="/commissioningde_commissioning" />
 <Route element={<NetworkInstallationsandMaintenance/>} path="/network_installations_and_maintenance" />


 <Route element={<DirectHirePlacementServices/>} path="/direct_hire_placement_services" />
 <Route element={<SupplementalStaffing/>} path="/supplemental_staffing" />
 <Route element={<OnsiteEmbeddedSupport/>} path="/onsite_embedded_support" />
 <Route element={<ProfessionalResources/>} path="/professional_resources" />
 <Route path="/career-details/:id" element={<SeparateCareerDetailsTwo />} />

 <Route element={<SearchComponent/>} path="/search_component" />


  </Routes>
  <Footer/>  
  { /*<BrowserRouter> <Routes> <Route element={<Home/>} path="/" /> <Route element={<AboutUs/>} path="/AboutUs " /> </Routes> </BrowserRouter>*/}
 </div>
);
  
}
export default App;
