import React from "react";
import Revolutionize from "./Revolutionize";
import { NavLink } from "react-router-dom";
import servicedata from "./servicedata.js";

function ManagedServices() {
  const data = servicedata.ManagedServices;
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="fade-in">
      <div className="inner-banner-wrap m-t-1 ManagedServices-banner">
        <div className="wrapper">
          <h1 className="banner-text-center">{data.title}</h1>
          <p>{data.shorttitle}</p>
        </div>
      </div>
      <div className="services-r-bg">
        <div className="wrapper">
          {data.description.map((description, index) => (
            <p key={index}>{description}</p>
          ))}
        </div>
      </div>
      <div className="services-r-bg-1 ManagedServices-img">
        <div className="wrapper-4">
          <div className="services-r-list-wrap">
            <ul>
              {data.sublists.map((item, index) => (
                <li key={index}>
                  <NavLink to={`../${item.link}`} onClick={scrollToTop}>
                  {item.name}
                  </NavLink>
                 
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <Revolutionize />
    </div>
  );
}
export default ManagedServices;
