import React from "react";
import Banner from "../images/Banner-Two.gif";
import ManagedServicesImg from "../images/Strategic-Partner-New.png";
import WeareBestSlider from "./WeareBestSlider";
//import VoiceSlider from './VoiceSlider'
import ClientsSlider from "./ClientsSlider";
import PatnersSlider from "./PatnersSlider";
//import CaseStudies from "./CaseStudies";
//import Tab from './Tab'

import EngineeringandITServices from "../images/EngineeringandITServices-home.png";
import ManagedServices from "../images/ManagedServices-home.png";
import ItDigitalTransfer from "../images/NetworkInfrastructure-home.png";
import AudioVisual from "../images/AudioandVisual-home.png";
import StaffingServices from "../images/StaffingAugumentation-home.png";

import { NavLink } from "react-router-dom";
import NoProfile from "../images/profile-1.jpg";
import ControlledCarousel from "./ControlledCarousel";
import MapImg1 from "../images/map.png";

function Home() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <ControlledCarousel />
      {/* 
      <div className="banner-wrap m-t-1">
        <div className="wrapper">
          <div className="banner-sec-wrap">
            <div className="banner-sec-1">
           
              <h1>
                Nfinity <b>Global</b> enables and empowers our customers to be
                profitable in their operations and <b> succeed in global expansions. </b>
              </h1>

              <NavLink
                to="../AboutUs"
                onClick={scrollToTop}
                className="submit-1 m-t-2"
              >
                Learn More
              </NavLink>
            </div>
            <div className="banner-sec-2">
              
            </div>
          </div>
        </div>
      
      </div> */}

      <div className="inner-page-bg-1 white-bg-1">
        <div className="wrapper">
          <div className="strategic-partner-main">
            <div className="strategic-partner-1  ">
              <div className="heading-2 w-80-1">
                <h2>
                  Your Dependable <b>Strategic</b> Partner
                </h2>
              </div>
              <p>
                Nfinity offers a comprehensive suite of Managed & IT Engineering
                Services, from expert help desk support, innovative engineering,
                advanced hardware procurement and robust security. Trust Nfinity
                as your steadfast and reliable partner in success.
              </p>
              <NavLink
                to="../ClientExperience"
                onClick={scrollToTop}
                className="submit-1 m-t-2"
              >
                Learn More
              </NavLink>
            </div>
            <div className="strategic-partner-2">
              <img src={ManagedServicesImg} alt="ManagedServicesImg" />
            </div>
          </div>
        </div>
      </div>
      <div className="best-bg">
        <div className="wrapper">
          <div className="heading-center-2">
            <h2>
              Our <b>Services</b> at a Glance
            </h2>
            <p>
              Your needs, our expertise: A concise look at our diverse service
              portfolio.
            </p>
          </div>

          <div className="home-services-border-wrap">
            <div className="home-services-border-1">
              <NavLink to="EngineeringandITServices" onClick={scrollToTop}>
                <img
                  src={EngineeringandITServices}
                  alt={EngineeringandITServices}
                />
                <h3>
                  Engineering <br />
                  and IT Services
                </h3>
              </NavLink>
            </div>
            <div className="home-services-border-1">
              <NavLink to="ManagedServices" onClick={scrollToTop}>
                <img src={ManagedServices} alt={ManagedServices} />
                <h3>
                  Managed <br />
                  Services
                </h3>
              </NavLink>
            </div>
            <div className="home-services-border-1">
              <NavLink to="AudioandVisual" onClick={scrollToTop}>
                <img src={AudioVisual} alt={AudioVisual} />
                <h3>
                  Audio And <br />
                  Visual
                </h3>
              </NavLink>
            </div>
            <div className="home-services-border-1">
              <NavLink to="NetworkInfrastructure" onClick={scrollToTop}>
                <img src={ItDigitalTransfer} alt={ItDigitalTransfer} />
                <h3>
                  Network <br />
                  Infrastructure{" "}
                </h3>
              </NavLink>
            </div>

            <div className="home-services-border-1">
              <NavLink to="StaffingAugumentation" onClick={scrollToTop}>
                <img src={StaffingServices} alt={StaffingServices} />
                <h3>
                  Staff <br />
                  Augumentation
                </h3>
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="voices-of-customers-bg">
        <div className="clients-wrap">
          <div className="wrapper">
            <div className="heading-center-2">
              <h2>
                Why align with <b>Nfinity Global</b>
              </h2>
              <p>
                We combine strategy and execution to help organizations
                accelerate growth and realize a brighter future.
              </p>
              <p>
                We turn complex technology solutions into a practical and
                actionable way forward. Then we help deliver them globally.
              </p>
            </div>
            <div className="map-2">
              <img src={MapImg1} alt={MapImg1} />
            </div>
          </div>
        </div>
      </div>

      <div className="voices-of-customers-bg">
        <div className="clients-wrap">
          <div className="wrapper">
            <div className="heading-center-2">
              <h2>
                Our <b>Clients</b>
              </h2>
            </div>
            <ClientsSlider />
          </div>
        </div>
      </div>
      <div className="wrapper-2">
        <hr></hr>
      </div>

      <div className="voices-of-customers-bg">
        <div className="clients-wrap">
          <div className="wrapper">
            <div className="heading-center-2">
              <h2>
                Our <b>Partners</b>
              </h2>
            </div>
            <PatnersSlider />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
