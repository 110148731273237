const npatners = [
    {
        albumId: 3,
        id: 1,
        title: "",
        url: "./images/patner-1.png"
      },
      {
        albumId: 3,
        id: 2,
        title: "",
        url: "./images/patner-2.png"
      },
      {
        albumId: 3,
        id: 3,
        title: "",
        url: "./images/patner-3.png"
      },

      {
        albumId: 3,
        id: 4,
        title: "",
        url: "./images/patner-4.png"
      },
      {
        albumId: 3,
        id: 5,
        title: "",
        url: "./images/patner-5.png"
      },
      {
        albumId: 3,
        id: 6,
        title: "",
        url: "./images/patner-6.png"
      },

     

      
]
export default npatners; 