import React from "react";
import Revolutionize from "./Revolutionize";
import { NavLink } from "react-router-dom";
import servicedata from "./servicedata.js";

function PreventativeMaintenance() {
  const data = servicedata.PreventativeMaintenance;
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="fade-in">
      <div className="inner-banner-wrap m-t-1">
        <div className="wrapper">
          <h1 className="banner-text-center">{data.title}</h1>
          <p>{data.shorttitle}</p>
        </div>
      </div>
      <div className="inner-services-r-bg">
        <div className="wrapper">
          <p>{data.description.content}</p>
          <h4>Why Prioritize Data Cabling in AV Services?  </h4>
          <p>{data.description.content2}</p>
          <h4>
          Key Features of our AV Services - Data Cabling:
          </h4>
          {data.description.services.map((ArchitectureDesignSetup, index) => (
            <p key={index}>{ArchitectureDesignSetup}</p>
          ))}
          <h4>Why Choose NFINITY Global?</h4>
          {data.description.nfinityglobal.map(
            (ArchitectureDesignSetup, index) => (
              <p key={index}>{ArchitectureDesignSetup}</p>
            )
          )}
        
        </div>
      </div>
    </div>
  );
}
export default PreventativeMaintenance;
